import React, { useState, useEffect, useRef } from 'react';

import Col from 'react-bootstrap/Col';

export default function ConditionalColumnSpan(props) {
  const [md, setMd] = useState(4)

  const container = useRef(null)

  useEffect(() => {
    const containerTooNarrow = container.current.clientWidth < 150
    const parentTooNarrow = props.containerRef ? props.containerRef.current.clientWidth < 500 : false
    if ((parentTooNarrow || containerTooNarrow) && md === 4) setMd(12)
  }, [props, md])

  return (
    <Col xs={12} md={md} className="conditionalColumnSpan" ref={container}>
      {props.children}
    </Col>
  )
}