import React, { useState } from 'react';

import { Maximize, Minimize } from 'react-feather';

export default function MinMaxIcon(props) {
  const [fullscreen, setFullscreen] = useState(false);

  function openFullscreen() {
    const elem = document.querySelector('#planpoint-root');

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }

    setFullscreen(true)
  }

  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }

    setFullscreen(false)
  }

  if (fullscreen) {
    return (
      <div className="grid-list-icon mr-2" onClick={() => closeFullscreen()}>
        <Minimize />
      </div>
    )
  } else {
    return (
      <div className="grid-list-icon mr-2" onClick={() => openFullscreen()}>
        <Maximize />
      </div>
    )
  }
}
