import React, { useState, useEffect, useRef } from 'react';

import i18nService from '../../helpers/i18nService';
import eventService from '../../helpers/eventService';

import Formatter from '../../helpers/formatter';

import Branding from '../../components/Branding/Branding';
import UnitList from '../../components/UnitList/UnitList';
import EmbedModal from '../../components/Modal/EmbedModal';
import SignupModal from '../../components/Modal/SignupModal';
import GalleryModal from '../../components/GalleryModal2/GalleryModal';
import FinishesModal from '../../components/Modal/FinishesModal';
import ShortlistMenu from '../../components/ShortlistMenu/ShortlistMenu';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';

import Select from 'react-select';

import { ArrowRight, ArrowLeft } from 'react-feather';

import useWindowSize from '../../hooks/useWindowSize/useWindowSize';

import backArrow from '../../assets/back-arrow.svg';

import Env from '../../Environments';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';

import './style.css';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

export default function Unit(props) {
  const [unit, setUnit] = useState({});
  const [project, setProject] = useState({});
  const [model, setModel] = useState();
  const [fetchedProject, setFetchedProject] = useState(false)
  const [floors, setFloors] = useState([])
  const [units, setUnits] = useState([])
  const [show3DModal, setShow3DModal] = useState(false)
  const [showGalleryModal, setShowGalleryModal] = useState(false)
  const [showSignupModal, setShowSignupModal] = useState(false)
  const [showFinishesModal, setShowFinishesModal] = useState(false)
  const [tableSorting, setTableSorting] = useState({ property: 'name', asc: false })
  const [showSignupNotification, setShowSignupNotification] = useState(false)
  const [width, height] = useWindowSize();

  const canvasAreaRef = useRef(null)
  const panelRef = useRef(null)
  const canvasPlaceholderRef = useRef(null)

  const nextElRef = useRef(null)
  const prevElRef = useRef(null)

  useEffect(() => {
    if (!fetchedProject) {
      if (props.selectedProject) {
        setProject(props.selectedProject)
      } else if (props.project) {
        setProject(props.project)
      }

      setFloors(props.floors)
      setUnits(props.units)

      props.setIsLoading(false)
      setFetchedProject(true)

      for (let unit of props.units) {
        if (unit._id === props.unit._id) {
          setUnit(unit)
          if (unit.unitmodels.length) setModel(unit.unitmodels[0])
        }
      }
    }

  }, [props, width, height, unit, project, model, fetchedProject]);

  async function selectUnit(unit) {
    props.selectUnit(unit, false)

    setUnit(unit)
  }

  function setSortingBy(property) {
    setTableSorting({
      property: property,
      asc: property === tableSorting.property ? !tableSorting.asc : 0
    })
  }

  function successfulSignup() {
    setShowSignupModal(false)
    setShowSignupNotification(true)
  }

  let optionalInclusionRow;
  if (project && project.showInclusions) {
    let inclusionBadges = []
    for (let incl of ((model || unit).inclusions || '').split(',')) {
      inclusionBadges.push(<span key={incl} className="badge badge-primary mr-1 mb-1">{incl.trim()}</span>)
    }

    optionalInclusionRow = (
      <div>
        <Dropdown.Divider />

        <Row>
          <Col>
            <small className="text-muted">{i18nService.i18n(props.locale, 'unitlist.inclusions')}</small>
            <h5>{inclusionBadges}</h5>
          </Col>
        </Row>
      </div>
    )
  }

  let scope = model || unit;

  let bedroomCount = parseInt(scope.bedrooms || 0);
  if (isNaN(bedroomCount)) bedroomCount = scope.bedrooms

  function reveal3DModal() {
    eventService.logEvent('unit', scope._id, '3D_view', '')

    setShow3DModal(true)
  }

  function showGallery() {
    eventService.logEvent('unit', scope._id, 'gallery_view', '')

    setShowGalleryModal(true)
  }

  function showFinishes() {
    eventService.logEvent('unit', scope._id, 'finishes_view', '')

    setShowFinishesModal(true)
  }

  function initiateDownload(evt, url) {
    evt.preventDefault()

    eventService.logEvent('unit', scope._id, 'download_pdf', '')

    window.open(url)
  }

  let conditional3dVisitButton;
  if (scope.embedCode) {
    conditional3dVisitButton = <Col xs={6}><Button variant="secondary" onClick={() => reveal3DModal()} className="unit-action mb-3 w-100">{i18nService.i18n(props.locale, 'unit.3d_visit')}</Button></Col>
  }

  let conditionalGalleryButton;
  let allImages = [...(scope.images || []), ...(scope.clonedImages || [])]
  let allFinishes = [...(scope.finishes || []), ...(scope.clonedFinishes || [])]

  if (allImages.length) {
    conditionalGalleryButton = <Col xs={6}><Button variant="secondary" onClick={() => showGallery()} className="unit-action mb-3 w-100">{i18nService.i18n(props.locale, 'unit.view_gallery')}</Button></Col>
  }

  let conditionalPdfButton;
  if (scope.downloadableAsset) {
    conditionalPdfButton = (
      <Col xs={6}>
        <Button variant="secondary" className="unit-action mb-3 w-100" onClick={e => initiateDownload(e, scope.downloadableAsset)}>
          {i18nService.i18n(props.locale, 'unit.download_pdf')}
        </Button>
      </Col>
    )
  }

  let conditionalSignupButton;
  if (project.enableForms) {
    conditionalSignupButton = <Col xs={6}><Button variant="secondary" onClick={() => setShowSignupModal(true)} className="unit-action mb-3 w-100">{i18nService.i18n(props.locale, 'signupform.trigger')}</Button></Col>
  }

  let conditionalFinishesButton;
  if (project.showUnitFinishes && scope.finishes && scope.finishes.length) {
    conditionalFinishesButton = (
      <Col xs={6}>
        <Button
          variant="secondary"
          className="unit-action mb-3 w-100"
          onClick={() => showFinishes()}
          >
          {i18nService.i18n(props.locale, 'unit.showfinishes')}
        </Button>
      </Col>
    )
  }

  function dynamicOptionalProperties(src) {
    let optionalProperties = [];

    let optionalPrices;
    if (project && project.showPrices) {
      let priceLabel = i18nService.i18n(props.locale, 'unit.price')
      if (project.pricesStartingAt) {
        priceLabel = i18nService.i18n(props.locale, 'unitlist.price_from')
      }

      let taxLabel;
      if (project.showTaxes) {
        let lstring = project.priceBeforeTax ? 'unitlist.before_taxes' : 'unitlist.incl_taxes'
        if (project.projectType === 'commercial') {
          lstring = project.priceBeforeTax ? 'unitlist.gross' : 'unitlist.net'
        }
        taxLabel = `(${i18nService.i18n(props.locale, lstring)})`
      }

      let priceValue;
      if (src.price && src.price > 0) {
        priceValue = src.price.toLocaleString(`${props.locale.match('showcase') ? 'en' : props.locale}-CA`, { style: 'currency', currency: 'CAD', minimumFractionDigits: 0 }).replace(/[A-Z]/g, '')
      } else {
        priceValue = i18nService.i18n(props.locale, 'unitList.tbd')
      }

      optionalPrices = (
        <Col>
          <small className="text-muted">{priceLabel} {taxLabel}</small>
          <h5>{priceValue}</h5>
        </Col>
      )
    }

    let optionalAvailability;
    if (project && project.showAvailability) {
      let availabilityText = src.availability
      if (project.rentalObject && src.availability === 'Sold') {
        availabilityText = 'Leased'
      }

      optionalAvailability = (
        <Col>
          <small className="text-muted">{i18nService.i18n(props.locale, 'unit.availability')}</small>
          <h5 className="text-capitalize">{i18nService.translate(props.locale, availabilityText || '')}</h5>
        </Col>
      )
    }

    let optionalBathrooms;
    if (project && project.showBathrooms) {
      optionalBathrooms = (
        <Col>
          <small className="text-muted">{i18nService.i18n(props.locale, 'unitlist.bathrooms')}</small>
          <h5>{src.bathrooms || '-'}</h5>
        </Col>
      )
    }

    let optionalParking;
    if (project && project.showParking) {
      optionalParking = (
        <Col>
          <small className="text-muted">{i18nService.i18n(props.locale, 'unitlist.parking')}</small>
          <h5>{src.parking || '-'}</h5>
        </Col>
      )
    }

    let optionalOrientation;
    if (project && project.showOrientation) {
      optionalOrientation = (
        <Col>
          <small className="text-muted">{i18nService.i18n(props.locale, 'unitlist.orientation')}</small>
          <h5>{src.orientation || '-'}</h5>
        </Col>
      )
    }

    let optionalDeliveryDate;
    if (project && project.deliveryDates) {
      let dateValue;
      if (['Leased', 'Sold', 'Unavailable'].indexOf(src.availability) !== -1) {
        dateValue = <h5 style={{ opacity: 0.5 }}>N/A</h5>
      } else {
        dateValue = <h5>{src.deliveryDate ? Formatter.formatDate(unit.deliveryDate) : '-'}</h5>
      }

      optionalDeliveryDate = (
        <Col>
          <small className="text-muted">{i18nService.i18n(props.locale, 'unitlist.deliverydate')}</small>
          {dateValue}
        </Col>
      )
    }

    let optionalPropertyColumns = [
      optionalPrices, optionalAvailability, optionalBathrooms, optionalParking,
      optionalOrientation, optionalDeliveryDate
    ];

    let currentRow = []
    let rowNb = 0;
    for (let optionalColumn of optionalPropertyColumns) {
      if (optionalColumn !== undefined) {
        currentRow.push(optionalColumn)

        if (currentRow.length === 2) {
          optionalProperties.push(<Dropdown.Divider key={`divider-${rowNb}`} />)
          optionalProperties.push(
            <Row key={`row-${rowNb}`}>
              {currentRow[0]}
              {currentRow[1]}
            </Row>
          )
          currentRow = []
          rowNb++
        }
      }
    }

    if (currentRow.length) {
      optionalProperties.push(<Dropdown.Divider key='drpdwn-dvdr-X' />)
      optionalProperties.push(
        <Row key='drpdwn-rw-X'>
          {currentRow[0]}
          <Col></Col>
        </Row>
      )
    }

    return optionalProperties
  }

  let bedroomColumn;
  if (project && !project.landOnly) {
    bedroomColumn = (
      <Col>
        <small className="text-muted">{i18nService.i18n(props.locale, 'unit.bedrooms')}</small>
        <h5>{bedroomCount}</h5>
      </Col>
    )
  }

  const icon = (state) => ({
    alignItems: 'center',
    display: 'flex',

    ':after': {
      color: '#2358e3',
      verticalAlign: 'top',
      content: state.isSelected ? '"L"' : '""',
      display: 'inline-block',
      position: 'absolute',
      marginRight: 10,
      right: 0,
      height: 16,
      width: 16,
      fontFamily: 'arial',
      transform: 'scaleX(-1) rotate(-35deg)'
    },
  });

  const customSelectStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        ...icon(state),
        cursor: 'pointer',
        background: 'none !important',
        backgroundColor: 'none !important',
        boxShadow: 'none',
        color: state.isSelected ? '#2358e3' : '#222',
        fontWeight: state.isSelected ? '600' : '500'
      }
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: '#495057',
        fontWeight: '600',
        lineHeight: '1.5',
        fontSize: '0.875rem'
      }
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: '#495057',
        fontWeight: '600',
        lineHeight: '1.5',
        fontSize: '0.875rem'
      }
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: '999 !important',
        borderRadius: '5px',
        background: 'white !important',
        backgroundColor: 'white !important'
      }
    },
    indicatorsContainer: (provided, state) => {
      return {
        ...provided,
        display: 'none'
      }
    },
    control: (provided, state) => {
      return {
        ...provided,
        paddingTop: '22px',
        paddingBottom: '10px',
        border: 'none',
        background: 'none',
        outline: 'none',
        boxShadow: 'none',
        cursor: 'pointer'
      }
    },
    container: (provided, state) => {
      return {
        ...provided,
        zIndex: '999 !important',
        background: 'none',
        cursor: 'pointer'
      };
    }
  }

  let unitStats;
  if (unit.unitmodels && unit.unitmodels.length) {
    if (model) {
      unitStats = (
        <Row className="unit-stats">
          <Col xs={6}>
            <Row>
              <Col xs={4}>
                <small className="text-muted">{i18nService.i18n(props.locale, 'unit.unit')}</small>
                <h5>{unit.name}</h5>
              </Col>

              <Col xs={8}>
                <Form.Group controlId="unitForm.SelectCustom" className="table-filter">
                  <Form.Label>{i18nService.i18n(props.locale, 'globals.pre_choose')} {i18nService.i18n(props.locale, 'unit.model')} {i18nService.i18n(props.locale, 'globals.post_choose')}</Form.Label>
                  <Select
                    styles={customSelectStyles}
                    classNamePrefix="react-select"
                    options={unit.unitmodels.map(b => ({ value: b, label: b.type }))}
                    isSearchable={false}
                    isClearable={false}
                    hideSelectedOptions={false}
                    isMulti={false}
                    placeholder={model.type}
                    onChange={e => setModel(e.value)}
                    />
                </Form.Group>
              </Col>
            </Row>

            <Dropdown.Divider />

            <Row>
              <Col>
                <small className="text-muted">{i18nService.i18n(props.locale, 'unit.area')}</small>
                <h5>{model.squareFeet} {i18nService.i18n(props.locale, 'globals.ft²')}</h5>
              </Col>

              {bedroomColumn}
            </Row>

            {dynamicOptionalProperties(model)}
            {optionalInclusionRow}
          </Col>
        </Row>
      )
    } else {
      unitStats = (
        <Row className="unit-stats">
          <Col xs={6}>
            <Row>
              <Col xs={4}>
                <small className="text-muted">{i18nService.i18n(props.locale, 'unit.unit')}</small>
                <h5>{unit.name}</h5>
              </Col>

              <Col xs={8}>
                <Form.Group controlId="unitForm.SelectCustom" className="table-filter">
                  <Form.Label>{i18nService.i18n(props.locale, 'globals.pre_choose')} {i18nService.i18n(props.locale, 'unit.model')} {i18nService.i18n(props.locale, 'globals.post_choose')}</Form.Label>
                  <Select
                    styles={customSelectStyles}
                    classNamePrefix="react-select"
                    options={unit.unitmodels.map(b => ({ value: b, label: b.type }))}
                    isSearchable={false}
                    isClearable={false}
                    hideSelectedOptions={false}
                    isMulti={false}
                    onChange={e => setModel(e.value)}
                    />
                </Form.Group>
              </Col>
            </Row>

            <Dropdown.Divider />
          </Col>
        </Row>
      )
    }
  } else {
    unitStats = (
      <Row className="unit-stats">
        <Col xs={6}>
          <Row>
            <Col>
              <small className="text-muted">{i18nService.i18n(props.locale, 'unit.unit')}</small>
              <h5>{unit.name}</h5>
            </Col>

            <Col></Col>
            <Col></Col>
            <Col></Col>
          </Row>

          <Dropdown.Divider />

          <Row>
            <Col>
              <small className="text-muted">{i18nService.i18n(props.locale, 'unit.area')}</small>
              <h5>{unit.squareFeet} {i18nService.i18n(props.locale, 'globals.ft²')}</h5>
            </Col>

            {bedroomColumn}
          </Row>

          {dynamicOptionalProperties(unit)}
          {optionalInclusionRow}
        </Col>
      </Row>
    )
  }

  let unitImageSlides = [];
  if (scope && scope.layoutGallery && scope.layoutGallery.length) {
    scope.layoutGallery.forEach((img, i) => {
      unitImageSlides.push(
        <SwiperSlide key={`swprslds-${i}`}>
          <img alt="unit layout plan" src={img} className="img-fluid" />
        </SwiperSlide>
      )
    })
  } else {
    unitImageSlides.push(
      <SwiperSlide key='swprslds-X'>
        <img alt="unit layout plan" src={scope.layoutUrl} className="img-fluid" />
      </SwiperSlide>
    )
  }

  let swiperNavigation;
  if (unitImageSlides.length > 1) {
    swiperNavigation = (
      <>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          navigation={{
            nextEl: nextElRef.current,
            prevEl: prevElRef.current
          }}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return '<h5 className="' + className + '">' + (index + 1) + '</h5>';
            }
          }}
        >
          {unitImageSlides}
        </Swiper>
      </>
    )
  } else {
    swiperNavigation = unitImageSlides
  }

  const slm = (
    <ShortlistMenu
      selectUnit={unit => selectUnit(unit)}
      unit={unit}
      project={project}
      token={props.token}
      locale={props.locale}
      offsetRight={project.invertNav}
      />
  )

  let slmLeft;
  let slmRight;
  if (project.invertNav) {
    slmRight = slm
  } else {
    slmLeft = slm
  }

  let unitDescription;
  if (project && project.showUnitDescription && unit && unit.description) {
    unitDescription = (
      <Row className="my-4 unit-description-container">
        <p className="unit-description-body text-muted">{unit.description}</p>
      </Row>
    )
  }

  return (
    <div className={`Unit overflow-hidden ${project.invertNav ? 'Unit--top-nav' : ''}`} ref={panelRef}>
      <Toast
        show={showSignupNotification}
        onClose={() => setShowSignupNotification(false)}
        style={{ position: 'fixed', top: '24px', right: '24px', zIndex: 999 }}
        >
        <Toast.Header>
          <strong className="mr-auto">{i18nService.i18n(props.locale, 'signupform.thankyou')}!</strong>
        </Toast.Header>
        <Toast.Body>{ project.formsMessage }</Toast.Body>
      </Toast>

      <EmbedModal
        show={show3DModal}
        content={scope.embedCode}
        onClose={() => setShow3DModal(false)}
        />

      <SignupModal
        show={showSignupModal}
        onClose={() => setShowSignupModal(false)}
        onSuccess={() => successfulSignup()}
        unit={unit}
        project={project}
        token={props.token}
        locale={props.locale}
        />

      <GalleryModal
        isOpen={showGalleryModal}
        items={allImages}
        onClose={() => setShowGalleryModal(false)}
        />

      <FinishesModal
        isOpen={showFinishesModal}
        items={allFinishes}
        onClose={() => setShowFinishesModal(false)}
        />

      <Row className="h-100">
        <Col xs={12} lg={7} xl={8} className="position-relative overflow-hidden">
          <div className="panel-navigation" onClick={() => props.selectFloor(floors.filter(f => f._id === unit.floor)[0])}>
            <img alt="go-back" src={`${Env.url}${backArrow}`} />
          </div>

          <div
            ref={nextElRef}
            className="swiper-nav-right"
            style={{ display: unitImageSlides.length > 1 ? 'block' : 'none' }}
            >
            <ArrowRight size={20}/>
          </div>

          <div
            ref={prevElRef}
            className="swiper-nav-left"
            style={{ display: unitImageSlides.length > 1 ? 'block' : 'none' }}
            >
            <ArrowLeft size={20}/>
          </div>

          {swiperNavigation}

          <Branding target={canvasPlaceholderRef.current} showBranding={project && project.showBranding}/>

          <img
            className="main-image"
            ref={canvasPlaceholderRef}
            alt={scope.name}
            style={{width: ((canvasAreaRef.current || {}).clientWidth || 1), height: 'auto'}}
            src={scope.layoutUrl}
            />

          <div
            id="konva-container"
            ref={canvasAreaRef}
            style={scope.layoutUrl ? {backgroundImage: `url('${scope.layoutUrl}')`} : {}}
            />

          {slmLeft}
        </Col>

        <Col xs={12} lg={5} xl={4} className="mh-100">
          <div className="px-2 py-4">
            <Form>
              {unitStats}

              {unitDescription}

              <Row className="mt-3 mb-4">
                {conditionalPdfButton}
                {conditionalGalleryButton}
                {conditional3dVisitButton}
                {conditionalSignupButton}
                {conditionalFinishesButton}
              </Row>

              <Dropdown.Divider />

              <Row>
                <Col>
                  <p className="text-muted">{i18nService.i18n(props.locale, 'unit.similar_units_as')} {unit.name}</p>
                  <UnitList
                    locale={props.locale}
                    project={project}
                    units={units}
                    selectUnit={unit => selectUnit(unit, false)}
                    tableSorting={tableSorting}
                    setSortingBy={prop => setSortingBy(prop)}
                    exclude={unit}
                    prioritize
                    />
                </Col>
              </Row>
            </Form>
          </div>

          {slmRight}
        </Col>
      </Row>
    </div>
  )
}
