import React, { useState, useEffect } from 'react';
import axios from 'axios';

import i18nService from '../../helpers/i18nService';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { X } from 'react-feather';

import ReCAPTCHA from "react-google-recaptcha";

import './Modal.css'

export default function ShareModal(props) {
  const [units, setUnits] = useState()
  const [email1, setEmail1] = useState('')
  const [email2, setEmail2] = useState('')
  const [email3, setEmail3] = useState('')
  const [email4, setEmail4] = useState('')
  const [email5, setEmail5] = useState('')
  const [email6, setEmail6] = useState('')
  const [email7, setEmail7] = useState('')
  const [email8, setEmail8] = useState('')
  const [email9, setEmail9] = useState('')
  const [email10, setEmail10] = useState('')
  const [personalMessage, setPersonalMessage] = useState('')
  const [visibleEmailFields, setVisibleEmailFields] = useState(1)
  const [notARobot, setNotARobot] = useState(true)

  const emailAttrs = [
    { attr: email1, setter: setEmail1 },
    { attr: email2, setter: setEmail2 },
    { attr: email3, setter: setEmail3 },
    { attr: email4, setter: setEmail4 },
    { attr: email5, setter: setEmail5 },
    { attr: email6, setter: setEmail6 },
    { attr: email7, setter: setEmail7 },
    { attr: email8, setter: setEmail8 },
    { attr: email9, setter: setEmail9 },
    { attr: email10, setter: setEmail10 }
  ]

  const [modalStyle, setModalStyle] = useState({
    display: props.show ? 'flex' : 'none'
  })

  useEffect(() => {
    async function fetchUnits() {
      let fetchedUnits = []

      for (let unitId of props.units) {
        const response = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': props.token
          },
          url: `https://planpoint-v1.herokuapp.com/api/v1/units/${unitId}`
        })
        fetchedUnits.push(response.data)
      }

      setUnits(fetchedUnits)
    }

    setModalStyle({
      display: props.show ? 'flex' : 'none'
    })

    if (props.units && (!units || units.length !== props.units.length)) fetchUnits()
  }, [props.show, props.units, props.token, units])

  function hideAndClose() {
    setModalStyle({ opacity: 0 })

    setTimeout(props.onClose, 250)
  }

  async function sendMail(e) {
    e.preventDefault()

    axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': props.token
      },
      data: {
        recipients: emailAttrs.filter(ea => ea.attr).map(e => e.attr),
        units: units,
        message: personalMessage,
        location: document.location.href
      },
      url: `https://planpoint-v1.herokuapp.com/api/v1/units/share`
    })

    hideAndClose()
  }

  function addEmailField(evt) {
    evt.preventDefault()

    setVisibleEmailFields(visibleEmailFields+1)
  }

  function captchaSolved(value) {
    setNotARobot(true)
  }

  let emailInputs = [];
  for (let i = 0; i < visibleEmailFields; i++) {
    emailInputs.push(
      <div key={`email-input-${i}`} className="form-group">
        <input
          type="email"
          className="form-control"
          value={emailAttrs[i].attr}
          onChange={e => emailAttrs[i].setter(e.target.value)}
          />
      </div>
    )
  }

  let conditionalAddInputAction;
  if (visibleEmailFields < 10) {
    conditionalAddInputAction = (
      <a href="/" className="d-block mb-3" onClick={e => addEmailField(e)}>+ add email</a>
    )
  }

  let fieldsFilled = true;
  for (let i = 0; i < visibleEmailFields; i++) {
    if (!emailAttrs[i].attr) {
      fieldsFilled = false
      break
    }
  }

  let submitButton;
  if (notARobot && fieldsFilled) {
    submitButton = (
      <Button
        variant="primary"
        type="submit"
        style={{ background: (props.project || {}).accentColor, borderColor: (props.project || {}).accentColor }}
        >
        {i18nService.i18n(props.locale, 'signupform.share_now')}
      </Button>
    )
  } else {
    submitButton = (
      <Button
        disabled
        className="disabled"
        variant="primary disabled"
        type="button"
        style={{ background: (props.project || {}).accentColor, borderColor: (props.project || {}).accentColor }}
        >
        {i18nService.i18n(props.locale, 'signupform.share_now')}
      </Button>
    )
  }

  let reCaptcha;
  if (false) {
    reCaptcha = (
      <ReCAPTCHA
        sitekey="6Ld2WAkbAAAAACFpT-frE7U8Pdn0b8crXTm9uw88"
        onChange={captchaSolved}
      />
    )
  }

  return (
    <form className="Modal__overlay Modal__overlay--high" style={modalStyle} onSubmit={e => sendMail(e)}>
      <Card className="modal--slim">
        <X size={30} className="Modal__close" color="white" onClick={() => hideAndClose()}/>
        <h5 className="card-header bg-white">{i18nService.i18n(props.locale, 'signupform.share_units')}</h5>
        <Card.Body>
          <p className="text-muted">
            {i18nService.i18n(props.locale, 'signupform.share_intro')}
          </p>

          {emailInputs}

          {conditionalAddInputAction}

          <h5 className="mt-5">{i18nService.i18n(props.locale, 'signupform.add_message')}</h5>
          <p className="text-muted">
            {i18nService.i18n(props.locale, 'signupform.add_personal_message')}
          </p>
          <textarea
            style={{ resize: 'none' }}
            rows="4"
            className="form-control mb-5"
            value={personalMessage}
            onChange={e => setPersonalMessage(e.target.value)}
            />

          {reCaptcha}
        </Card.Body>

        <div className="card-footer bg-white text-right">
          {submitButton}
        </div>
      </Card>
    </form>
  )
}
