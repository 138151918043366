import axios from 'axios';

import Env from '../Environments';

const helpers = {
  logEvent: async function(resourceName, resource, eventName, eventNote) {
    let eventData = { name: eventName, note: eventNote }

    // it is possible to pass either a full resource (e.g. a floor object) or an
    // ID of a resource ... but both cases need to be handled
    if (resource._id) {
      eventData[resourceName] = resource._id
    } else {
      eventData[resourceName] = resource
    }

    await axios({
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: eventData,
      url: `${Env.url}/api/v1/events`
      // url: `http://localhost:5000/api/v1/events`
    })
  }
}

export default helpers;