import React, { useState, useEffect } from 'react';
import axios from 'axios';

import i18nService from '../../helpers/i18nService';
import eventService from '../../helpers/eventService';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { X } from 'react-feather';

import './Modal.css'

export default function SignupModal(props) {
  const [modalStyle, setModalStyle] = useState({
    display: props.show ? 'flex' : 'none'
  })
  const [newLeadFirstName, setNewLeadFirstName] = useState()
  const [newLeadLastName, setNewLeadLastName] = useState()
  const [newLeadEmail, setNewLeadEmail] = useState()
  const [newLeadPhone, setNewLeadPhone] = useState()
  const [newLeadMessage, setNewLeadMessage] = useState()
  const [units, setUnits] = useState()

  useEffect(() => {
    async function fetchUnits() {
      let fetchedUnits = []

      for (let unitId of props.units) {
        const response = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': props.token
          },
          url: `https://planpoint-v1.herokuapp.com/api/v1/units/${unitId}`
        })
        fetchedUnits.push(response.data)
      }

      setUnits(fetchedUnits)
    }

    setModalStyle({
      display: props.show ? 'flex' : 'none'
    })

    if (props.units && (!units || units.length !== props.units.length)) fetchUnits()
  }, [props.show, props.units, props.token, units])

  function hideAndClose() {
    setModalStyle({ opacity: 0 })

    setTimeout(() => {
      props.onClose()
    }, 250)
  }

  async function addLead(evt) {
    evt.preventDefault()

    if (props.unit) eventService.logEvent('unit', props.unit, 'signup', '')

    if (props.units && props.units.length) {
      props.units.forEach(u => eventService.logEvent('unit', u, 'signup', ''))
    }

    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': props.token
      },
      data: {
        leads: [...props.project.leads, {
          firstName: newLeadFirstName,
          lastName: newLeadLastName,
          email: newLeadEmail,
          phone: newLeadPhone,
          message: newLeadMessage,
          unit: props.unit ? props.unit.name : units.map(u => u.name).join(', ')
        }]
      },
      url: `https://planpoint-v1.herokuapp.com/api/v1/projects/${props.project._id}`
      // url: `http://localhost:5001/api/v1/projects/${props.project._id}`
    })

    props.onSuccess()

    setNewLeadFirstName('')
    setNewLeadLastName('')
    setNewLeadEmail('')
    setNewLeadPhone('')
    setNewLeadMessage('')
  }

  let modalTitle;
  if (props.unit) {
    modalTitle = (
      <h5 className="card-header bg-white">{i18nService.i18n(props.locale, 'signupform.headline')} {props.unit.name}</h5>
    )
  } else {
    modalTitle = (
      <h5 className="card-header bg-white">{i18nService.i18n(props.locale, 'signupform.headline')}</h5>
    )
  }

  let optionalUnitsRow;
  if (units && units.length) {
    optionalUnitsRow = (
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label>{i18nService.i18n(props.locale, 'unitlist.unit')}</label>
            <div>
              {units.map( u => (<span className="badge badge-primary mb-1 mr-1">{u.name}</span>))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <form className="Modal__overlay" style={modalStyle} onSubmit={e => addLead(e)}>
      <Card className="modal--slim">
        <X size={30} className="Modal__close" color="white" onClick={() => hideAndClose()}/>
        {modalTitle}
        <Card.Body className="overflow-auto">
          <div className="text-left">
            <fieldset>
              <div className="list-group list-group-flush">
                {optionalUnitsRow}

                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{i18nService.i18n(props.locale, 'signupform.firstname')}*</label>
                      <input type="text" className="form-control" value={newLeadFirstName} onChange={e => setNewLeadFirstName(e.target.value)} required/>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{i18nService.i18n(props.locale, 'signupform.lastname')}*</label>
                      <input type="text" className="form-control" value={newLeadLastName} onChange={e => setNewLeadLastName(e.target.value)} required/>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{i18nService.i18n(props.locale, 'signupform.email')}*</label>
                      <input type="email" className="form-control" value={newLeadEmail} onChange={e => setNewLeadEmail(e.target.value)} required/>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{i18nService.i18n(props.locale, 'signupform.phone')}*</label>
                      <input type="text" className="form-control" value={newLeadPhone} onChange={e => setNewLeadPhone(e.target.value)} required/>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>{i18nService.i18n(props.locale, 'signupform.message')}*</label>
                      <textarea className="form-control" value={newLeadMessage} onChange={e => setNewLeadMessage(e.target.value)} required/>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </Card.Body>

        <div className="card-footer bg-white text-right">
          <Button
            variant="primary"
            type="submit"
            style={{ background: (props.project || {}).accentColor, borderColor: (props.project || {}).accentColor }}
            >
            {i18nService.i18n(props.locale, 'signupform.cta')}
          </Button>
        </div>
      </Card>
    </form>
  )
}
