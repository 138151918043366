import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line
import Konva from 'konva';
import Select from 'react-select'

import i18nService from '../../helpers/i18nService';
import eventService from '../../helpers/eventService';

import Popover from '../../components/Popover';
import Branding from '../../components/Branding/Branding';
import MinMaxIcon from '../../components/MinMaxIcon/MinMaxIcon';
import UnitList from '../../components/UnitList/UnitList';
import ShortlistMenu from '../../components/ShortlistMenu/ShortlistMenu';
import ConditionalColumnSpan from '../../components/ConditionalColumnSpan';

import RangeSliderRow from '../../components/RangeSliderRow';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import useWindowSize from '../../hooks/useWindowSize/useWindowSize';

import initializeCanvas from '../../helpers/initializeCanvas';

import gridIconInactive from '../../assets/grid_view_icon_inactive.svg';
import gridIconActive from '../../assets/grid_view_icon_active.svg';
import listIconInactive from '../../assets/list_view_icon_inactive.svg';
import listIconActive from '../../assets/list_view_icon_active.svg';

import { ChevronLeft } from 'react-feather';

import Env from '../../Environments';

export default function Exterior(props) {
  const [project, setProject] = useState({})
  const [initialLoad, setInitialLoad] = useState(false)
  const [filterByFloor, setFilterByFloor] = useState([])
  const [filterByBedrooms, setFilterByBedrooms] = useState()
  const [filterByPrice, setFilterByPrice] = useState()
  const [filterByArea, setFilterByArea] = useState()
  const [gridView, setGridView] = useState(false)
  const [dynamicHeight, setDynamicHeight] = useState()
  const [tableSorting, setTableSorting] = useState({
    property: 'name', asc: true
  })
  const [selectedFloors, setSelectedFloors] = useState([]);

  const [width, height] = useWindowSize();

  const canvasAreaRef = useRef(null)
  const canvasPlaceholderRef = useRef(null)
  const panelRef = useRef(null)

  const filterRow = useRef(null)

  const tooltipContainer = useRef(null)

  useEffect(() => {
    if (!initialLoad && props.selectedProject) {
      setProject(props.selectedProject)
      setInitialLoad(true)
    }

    if (props.units) reinitializeCanvas()
    // eslint-disable-next-line
  }, [width, height, props.units, props.selectedProject, initialLoad]);

  function reinitializeCanvas() {
    if (project && project._id && canvasPlaceholderRef.current && canvasPlaceholderRef.current.clientWidth !== 0 && canvasPlaceholderRef.current.clientHeight !== 0) {
      initializeCanvas(canvasPlaceholderRef, panelRef, setDynamicHeight, getData(), project, goToFloor, 'floor', project.showFloorOverview, props.locale, project.projectImageUrl)
    } else {
      // try again in a second (wait for the project image to render fully)
      setTimeout(function() {
        reinitializeCanvas()
      }, 1000)
    }
  }

  function getData() {
    // initialize placeholder
    const placeholderWidth = 700 // base width
    const placeholderHeight = canvasPlaceholderRef.current.clientHeight*(700/canvasPlaceholderRef.current.clientWidth)

    let areas = {}
    for (let floor of props.floors) {
      const coordinates = JSON.parse(floor.path || '[]')
      const absolute = coordinates.map((e, i) => {
        if (i % 2) {
          // Y coordinate
          return placeholderWidth * e
        } else {
          return placeholderHeight * e
        }
      })


      let availableUnits = 0;
      for (let u of props.units) {
        if (u.availability === 'Available' && u.floor === floor._id) availableUnits++
      }

      areas[floor.name] = {
        editable: false,
        floor: floor,
        subline: project.showFloorOverview ? `${availableUnits} units available` : '',
        color: project.accentColor || 'rgba(15, 33, 49, 0.65)',
        points: absolute
      }
    }

    return areas;
  }

  let floorScope = 'floor';
  if (project.previewMode || project.projectType === 'land') {
    floorScope = 'phase'
  }

  function goToFloor(floor) {
    eventService.logEvent('floor', floor, 'view', 'from canvas')

    props.selectFloor(floor)
  }

  function setSortingBy(property) {
    setTableSorting({
      property: property,
      asc: property === tableSorting.property ? !tableSorting.asc : 0
    })
  }

  let canvasBreakpoints = [12, 12, 7, 8];
  let sidebarBreakpoints = [12, 12, 5, 4];

  if (props.locale === 'de') {
    canvasBreakpoints = [12, 12, 6, 7];
    sidebarBreakpoints = [12, 12, 6, 5];
  }

  if (props.locale === 'es' || props.locale === 'fr') {
    canvasBreakpoints = [12, 12, 6, 7];
    sidebarBreakpoints = [12, 12, 6, 5];
  }

  function selectFloors(e) {
    setSelectedFloors(e)
    setFilterByFloor(e.map(f => f.value))
  }

  const icon = (state) => ({
    alignItems: 'center',
    display: 'flex',

    ':after': {
      color: '#2358e3',
      verticalAlign: 'top',
      content: state.isSelected ? '"L"' : '""',
      display: 'inline-block',
      position: 'absolute',
      marginRight: 10,
      right: 0,
      height: 16,
      width: 16,
      fontFamily: 'arial',
      transform: 'scaleX(-1) rotate(-35deg)'
    },
  });

  const customSelectStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        ...icon(state),
        cursor: 'pointer',
        background: 'none !important',
        backgroundColor: 'none !important',
        boxShadow: 'none',
        color: state.isSelected ? '#2358e3' : '#222',
        fontWeight: state.isSelected ? '600' : '500'
      }
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: '#495057',
        fontWeight: '600',
        lineHeight: '1.5',
        fontSize: '0.875rem'
      }
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: '#495057',
        fontWeight: '600',
        lineHeight: '1.5',
        fontSize: '0.875rem'
      }
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: '999 !important',
        borderRadius: '5px',
        background: 'white !important',
        backgroundColor: 'white !important'
      }
    },
    indicatorsContainer: (provided, state) => {
      return {
        ...provided,
        display: 'none'
      }
    },
    control: (provided, state) => {
      return {
        ...provided,
        paddingTop: '22px',
        paddingBottom: '10px',
        border: 'none',
        background: 'none',
        outline: 'none',
        boxShadow: 'none',
        cursor: 'pointer'
      }
    },
    container: (provided, state) => {
      return {
        ...provided,
        background: 'none',
        cursor: 'pointer'
      };
    }
  }

  let customSelectPlaceholder;
  switch (selectedFloors.length) {
    case 1:
      customSelectPlaceholder = selectedFloors[0].label
      break;
    case 0:
      customSelectPlaceholder = i18nService.translate(props.locale, `All ${floorScope}s`)
      break;
    default:
      customSelectPlaceholder = selectedFloors.map((f, i) => (
        i === 0 ? f.label : f.label.split(' ').pop()
      )).join(', ')
  }

  let bedroomFilter;
  if (project && !project.previewMode && project.projectType !== 'commercial') {
    bedroomFilter = (
      <ConditionalColumnSpan containerRef={filterRow}>
        <Form.Group controlId="unitForm.SelectCustom" className="table-filter">
          <Form.Label>{i18nService.i18n(props.locale, 'globals.pre_choose')} {i18nService.i18n(props.locale, 'exterior.bedrooms')} {i18nService.i18n(props.locale, 'globals.post_choose')}</Form.Label>
          <Select
            styles={customSelectStyles}
            classNamePrefix="react-select"
            options={[{ value: undefined, label: i18nService.i18n(props.locale, 'exterior.all_bedrooms') }, ...props.availableBedrooms.map(b => ({ value: b, label: i18nService.translate(props.locale, b) }))]}
            isSearchable={false}
            isClearable={false}
            hideSelectedOptions={false}
            controlShouldRenderValue={true}
            isMulti={false}
            placeholder={i18nService.i18n(props.locale, 'exterior.all_bedrooms')}
            onChange={e => setFilterByBedrooms(e.value)}
            />
        </Form.Group>
      </ConditionalColumnSpan>
    )
  }

  let conditionalBackNav;
  if (props.groupId) {
    conditionalBackNav = (
      <div className="panel-navigation" onClick={() => props.selectGroup()}>
        <ChevronLeft color='white' />
      </div>
    )
  } else if (props.collectionId) {
    conditionalBackNav = (
      <div className="panel-navigation" onClick={() => props.setSelectedProjectId()}>
        <ChevronLeft color='white' />
      </div>
    )
  }

  let floorFilter;
  if (props.floors) {
    floorFilter = (
      <ConditionalColumnSpan containerRef={filterRow}>
        <Form.Group controlId="unitForm.SelectCustom" className="table-filter">
          <Form.Label>{i18nService.i18n(props.locale, 'globals.pre_choose')} {i18nService.i18n(props.locale, `exterior.${floorScope}`)} {i18nService.i18n(props.locale, 'globals.post_choose')}</Form.Label>
          <Select
            styles={customSelectStyles}
            options={props.floors.map(f => ({ value: f._id, label: i18nService.translate(props.locale, f.name) }))}
            closeMenuOnSelect={false}
            isSearchable={false}
            isClearable={false}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            isMulti={true}
            placeholder={customSelectPlaceholder}
            onChange={e => selectFloors(e)}
            />
        </Form.Group>
      </ConditionalColumnSpan>
    )
  }

  let listViewToggle = (
    <Col xs={12} md={4} className="d-flex flex-row flex-nowrap align-items-end justify-content-start">
      <Form.Group className="view-options" ref={tooltipContainer}>
        <span className="view-option">
          <Popover text="List view" />
          <img
            className="grid-list-icon-list mr-2"
            alt="grid-list-icon-grid"
            src={`${Env.url}${gridView ? listIconInactive : listIconActive}`}
            onClick={() => setGridView(false)}
            />
        </span>
        <span className="view-option">
          <Popover text="Grid view" />
          <img
            className="grid-list-icon-list mr-2"
            alt="grid-list-icon-list"
            src={`${Env.url}${gridView ? gridIconActive : gridIconInactive}`}
            onClick={() => setGridView(true)}
            />
        </span>
        <span className="view-option">
          <Popover text="Fullscreen" />
          <MinMaxIcon size={15} />
        </span>
      </Form.Group>
    </Col>
  )

  let rangeSliderOptions;
  if (project && (project.showPriceFilter || project.showAreaFilter)) {
    rangeSliderOptions = (
      <RangeSliderRow
        locale={props.locale}
        project={project}
        units={props.units}
        doneLoading={true}
        setFilterByPrice={v => setFilterByPrice(v)}
        setFilterByArea={v => setFilterByArea(v)}
        />
    )
  }

  return (
    <div className="Exterior overflow-hidden" ref={panelRef}>
      <Row className="h-100">
        <Col xs={canvasBreakpoints[0]} md={canvasBreakpoints[1]} lg={canvasBreakpoints[2]} xl={canvasBreakpoints[3]} className="position-relative px-0">
          {conditionalBackNav}

          <Branding target={canvasPlaceholderRef.current} showBranding={project && project.showBranding}/>

          <img
            className='main-image'
            ref={canvasPlaceholderRef}
            alt={project.name}
            style={{width: ((canvasAreaRef.current || {}).clientWidth || 1), height: 'auto'}}
            src={project.projectImageUrl}
            />

          <div
            id="konva-container"
            ref={canvasAreaRef}
            style={project.projectImageUrl ? {backgroundImage: `url('${project.projectImageUrl}')`} : {}}
            />

          <ShortlistMenu
            selectUnit={unit => props.selectUnit(unit)}
            project={project}
            token={props.token}
            locale={props.locale}
            />
        </Col>

        <Col xs={sidebarBreakpoints[0]} md={sidebarBreakpoints[1]} lg={sidebarBreakpoints[2]} xl={sidebarBreakpoints[3]} className="mh-100 planpoint-sidebar">
          <div className="px-2 py-4">
            <Form>
              <Row ref={filterRow}>
                {bedroomFilter}
                {floorFilter}
                {listViewToggle}
              </Row>

              {rangeSliderOptions}

              <Row>
                <Col>
                  <UnitList
                    locale={props.locale}
                    project={project}
                    units={props.units}
                    filterByFloor={filterByFloor}
                    filterByBedrooms={filterByBedrooms}
                    filterByPrice={filterByPrice}
                    filterByArea={filterByArea}
                    selectUnit={unit => props.selectUnit(unit)}
                    tableSorting={tableSorting}
                    setSortingBy={prop => setSortingBy(prop)}
                    gridView={gridView}
                    dynamicHeight={dynamicHeight}
                    />
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}
