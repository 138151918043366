import React, { useState, useEffect } from 'react';
import axios from 'axios';

import i18nService from '../../helpers/i18nService';

import Card from 'react-bootstrap/Card';

import { X } from 'react-feather';

import './Modal.css'

export default function FavoritesModal(props) {
  const [modalStyle, setModalStyle] = useState({
    display: props.show ? 'flex' : 'none'
  })
  const [units, setUnits] = useState()

  useEffect(() => {
    async function fetchUnits() {
      let fetchedUnits = []

      for (let unitId of props.units) {
        const response = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': props.token
          },
          url: `https://planpoint-v1.herokuapp.com/api/v1/units/${unitId}`
        })
        fetchedUnits.push(response.data)
      }

      setUnits(fetchedUnits)
    }

    setModalStyle({
      display: props.show ? 'flex' : 'none'
    })

    if (props.units && (!units || units.length !== props.units.length)) fetchUnits()
  }, [props.show, props.units, props.token, units])

  function hideAndClose() {
    setModalStyle({ opacity: 0 })

    setTimeout(() => {
      props.onClose()
    }, 250)
  }

  function closeAndVisitUnit(unit) {
    hideAndClose()
    props.selectUnit(unit)
  }

  let unitRows = [];
  for (let unit of (units || [])) {
    let goToCTA;
    if (props.unit && props.unit._id === unit._id) {
      goToCTA = (
        <button
          disabled
          className="btn btn-primary ml-2 disabled"
          style={{ background: props.project.accentColor, borderColor: props.project.accentColor }}
          >
          {i18nService.i18n(props.locale, 'signupform.viewUnit')}
        </button>
      )
    } else {
      goToCTA = (
        <button
          className="btn btn-primary ml-2"
          onClick={e => closeAndVisitUnit(unit)}
          style={{ background: props.project.accentColor, borderColor: props.project.accentColor }}
          >
          {i18nService.i18n(props.locale, 'signupform.viewUnit')}
        </button>
      )
    }

    unitRows.push(
      <li className="list-group-item d-flex justify-content-between align-items-center">
        {unit.name}
        <div className="unit-rows-actions">
          <a download
            target="_blank"
            rel="noopener noreferrer"
            href={unit.downloadableAsset}
            className="btn btn-secondary"
            >
            {i18nService.i18n(props.locale, 'unit.download_pdf')}
          </a>

          {goToCTA}
        </div>
      </li>
    )
  }

  return (
    <div className="Modal__overlay" style={modalStyle}>
      <Card className="modal--slim">
        <X size={30} className="Modal__close" color="white" onClick={() => hideAndClose()}/>
        <h5 className="card-header bg-white">{i18nService.i18n(props.locale, 'signupform.listAllTitle')}</h5>

        <ul className="list-group list-group-flush">{unitRows}</ul>
      </Card>
    </div>
  )
}
