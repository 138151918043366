const production = {
  url: 'https://planpoint-v1.herokuapp.com'
}

const development = {
  url: 'https://planpoint-v1.herokuapp.com'
  // url: 'http://localhost:5001'
}

export default process.env.NODE_ENV === 'production' ? production : development;
