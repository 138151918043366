import React, { useState, useEffect } from 'react';

import RangeSlider from './components/RangeSlider';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18nService from '../../helpers/i18nService';

import './style.css';

export default function RangeSliderRow(props) {
  const [priceBound, setPriceBound] = useState(0);
  const [areaBound, setAreaBound] = useState(0);

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minArea, setMinArea] = useState(0);
  const [maxArea, setMaxArea] = useState(0);

  useEffect(() => {
    if (props.units) {
      const mp = Math.max(...props.units.map(u => u.price))
      setMaxPrice(mp)
      setPriceBound(mp)

      const ma = Math.max(...props.units.map(u => u.squareFeet))
      setMaxArea(ma)
      setAreaBound(ma)
    }
  }, [props.units])

  function updateMaxPrice(v) {
    setMaxPrice(v)
    props.setFilterByPrice({ from: minPrice, to: v })
  }

  function updateMinPrice(v) {
    setMinPrice(v)
    props.setFilterByPrice({ from: v, to: maxPrice })
  }

  function updateMaxArea(v) {
    setMaxArea(v)
    props.setFilterByArea({ from: minArea, to: v })
  }

  function updateMinArea(v) {
    setMinArea(v)
    props.setFilterByArea({ from: v, to: maxArea })
  }

  let priceSlider;
  if (props.doneLoading && priceBound > 0 && props.project.showPriceFilter) {
    priceSlider = (
      <Col xs={6}>
        <label className="rsr-slider-label">
          Price from <span>${minPrice}</span> to <span>${maxPrice}</span>
        </label>
        <RangeSlider
          min={0}
          max={priceBound}
          setMinVal={v => updateMinPrice(v)}
          setMaxVal={v => updateMaxPrice(v)}
          />
      </Col>
    )
  }

  let areaSlider;
  if (props.doneLoading && areaBound > 0 && props.project.showAreaFilter) {
    areaSlider = (
      <Col xs={6}>
        <label className="rsr-slider-label">
          Area from <span>{minArea}{i18nService.i18n(props.locale, 'globals.ft²')}</span> to <span>{maxArea}{i18nService.i18n(props.locale, 'globals.ft²')}</span>
        </label>
        <RangeSlider
          min={0}
          max={areaBound}
          setMinVal={v => updateMinArea(v)}
          setMaxVal={v => updateMaxArea(v)}
          />
      </Col>
    )
  }

  return (
    <Row className="RangeSliderRow">
      {priceSlider}
      {areaSlider}
    </Row>
  )
}
