import dict_en from './i18n/en.json'
import dict_en_showcase from './i18n/en_showcase.json'
import dict_de from './i18n/de.json'
import dict_fr from './i18n/fr.json'
import dict_es from './i18n/es.json'
import dict_zh from './i18n/zh.json'

const dictionaries = {
  'en': dict_en,
  'en_showcase': dict_en_showcase,
  'de': dict_de,
  'fr': dict_fr,
  'es': dict_es,
  'zh': dict_zh
}

const helpers = {
  i18n: function(locale, path) {
    const dict = dictionaries[locale]

    if (dict) {
      try {
        let response = path.toLowerCase().split('.').reduce((d, p) => d[p], dict)
        return (typeof response === 'string') ? response : path
      } catch(e) {
        return path
      }
    } else {
      return path
    }
  },
  translate: function(locale, text) {
    const dict = dictionaries[locale]

    if (dict) {
      // split text into words and translate them
      return (text || '').split(' ').map(t => dict.globals[t.toLowerCase()] || t).join(' ')
    } else {
      return text
    }
  }
}

export default helpers;
