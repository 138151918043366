import React, { useState, useEffect } from 'react';

import i18nService from '../../helpers/i18nService';
import eventService from '../../helpers/eventService';

import Toast from 'react-bootstrap/Toast';

import SignupModal from '../Modal/SignupModal';
import FavoritesModal from '../Modal/FavoritesModal';
import ShareModal from '../Modal/ShareModal';

import heartIcon from '../../assets/planpoint-heart.svg';

import './style.css';

export default function ShortlistMenu(props) {
  const [showMenu, setShowMenu] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showSignupNotification, setShowSignupNotification] = useState(false);
  const [initializedCookies, setInitializedCookies] = useState(false);
  const [shortlist, setShortlist] = useState([]);
  const [showFavorites, setShowFavorites] = useState(false);
  const [showShare, setShowShare] = useState(false);

  useEffect(() => {
    if (!initializedCookies) {
      let savedUnits = getCookie('planpoint-shortlist')

      if (savedUnits && savedUnits !== '') {
        setShortlist(savedUnits.split('|'))
      }

      setInitializedCookies(true)
    }
  }, [setInitializedCookies, initializedCookies])

  function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function removeUnit(u) {
    const newShortlist = shortlist.filter(e => e !== u._id)
    setCookie('planpoint-shortlist', newShortlist.join('|'), 28);
    setShortlist(newShortlist)
    setShowMenu(false)
  }

  function addUnit(u) {
    eventService.logEvent('unit', u._id, 'add_to_favorites', '')
    
    const newShortlist = [...shortlist, u._id]
    setCookie('planpoint-shortlist', newShortlist.join('|'), 28);
    setShortlist(newShortlist)
    setShowMenu(false)
  }

  function successfulSignup() {
    setShowSignupModal(false)
    setShowSignupNotification(true)
  }

  let optionalUnitAction;
  if (props.unit) {
    if (shortlist.indexOf(props.unit._id) !== -1) {
      optionalUnitAction = (
        <div className="ShortlistMenu-item" onClick={e => removeUnit(props.unit)}>
          <span className="ShortlistMenu-item-label">{i18nService.i18n(props.locale, 'shortlist.remove_unit_before')} {props.unit.name} {i18nService.i18n(props.locale, 'shortlist.remove_unit_after')}</span>
        </div>
      )
    } else {
      optionalUnitAction = (
        <div className="ShortlistMenu-item" onClick={e => addUnit(props.unit)}>
          <span className="ShortlistMenu-item-label">{i18nService.i18n(props.locale, 'shortlist.add_unit_before')} {props.unit.name} {i18nService.i18n(props.locale, 'shortlist.add_unit_after')}</span>
        </div>
      )
    }
  }

  let optionalScheduleAction;
  if (shortlist && shortlist.length) {
    optionalScheduleAction = (
      <div className="ShortlistMenu-item" onClick={e => setShowSignupModal(true)}>
        <span className="ShortlistMenu-item-label">{i18nService.i18n(props.locale, 'signupform.trigger')}</span>
      </div>
    )
  }

  let optionalFavListAction;
  if (shortlist && shortlist.length) {
    optionalFavListAction = (
      <div className="ShortlistMenu-item" onClick={e => setShowFavorites(true)}>
        <span className="ShortlistMenu-item-label">{i18nService.i18n(props.locale, 'signupform.listAll')}</span>
      </div>
    )
  }

  let optionalShareListAction;
  if (shortlist && shortlist.length) {
    optionalShareListAction = (
      <div className="ShortlistMenu-item" onClick={e => setShowShare(true)}>
        <span className="ShortlistMenu-item-label">{i18nService.i18n(props.locale, 'signupform.share')}</span>
      </div>
    )
  }

  let shortlistMenu;
  if (props.unit || shortlist.length) {
    shortlistMenu = (
      <>
        <div
          className="ShortlistMenu-toggle"
          onClick={e => setShowMenu(!showMenu)}
          >
          <img src={`https://planpoint-v1.herokuapp.com${heartIcon}`} alt="Planpoint shortlist" className="ShortlistMenu-toggle-image" />
          <span className="ShortlistMenu-toggle-count">{ shortlist.length }</span>
        </div>

        <div className={`ShortlistMenu-menu ${showMenu ? 'ShortlistMenu-menu--visible' : ''}`}>
          {optionalUnitAction}
          {optionalScheduleAction}
          {optionalFavListAction}
          {optionalShareListAction}
        </div>
      </>
    )
  }

  return (
    <div className='ShortlistMenu'>
      <Toast
        show={showSignupNotification}
        onClose={() => setShowSignupNotification(false)}
        style={{ position: 'fixed', top: '24px', right: '24px', zIndex: 999 }}
        >
        <Toast.Header>
          <strong className="mr-auto">{i18nService.i18n(props.locale, 'signupform.thankyou')}!</strong>
        </Toast.Header>
        <Toast.Body>{(props.project || {}).formsMessage}</Toast.Body>
      </Toast>

      <div className={`ShortlistMenu-container ${props.offsetRight ? 'ShortlistMenu-container--offset-right' : ''}`}>
        {shortlistMenu}
      </div>

      <SignupModal
        show={showSignupModal}
        onClose={() => setShowSignupModal(false)}
        onSuccess={() => successfulSignup()}
        units={shortlist}
        project={props.project}
        token={props.token}
        locale={props.locale}
        />

      <FavoritesModal
        show={showFavorites}
        onClose={() => setShowFavorites(false)}
        units={shortlist}
        unit={props.unit}
        selectUnit={unit => props.selectUnit(unit)}
        project={props.project}
        token={props.token}
        locale={props.locale}
        />

      <ShareModal
        show={showShare}
        onClose={() => setShowShare(false)}
        units={shortlist}
        project={props.project}
        token={props.token}
        locale={props.locale}
        />
    </div>
  )
}
