import Konva from 'konva';

import i18nService from './i18nService';

let stage, shapesLayer, tooltipLayer, backgroundLayer, stageScale;

export default function initializeCanvas(canvasPlaceholderRef, panelRef, setDynamicHeight, areas, project, touchAction, touchActionProperty, wideTooltip, locale = 'en', backgroundImageUrl) {
  function transformPoints(points) {
    let newPoints = []
    let isX = true

    let newWidth = canvasPlaceholderRef.current.clientWidth
    let newHeight = canvasPlaceholderRef.current.clientHeight

    let oldWidth = 700
    let oldHeight = newHeight * (oldWidth / newWidth)

    for (let point of points) {
      newPoints.push(isX ? (point * (newWidth/oldWidth)) : (point * (newHeight/oldHeight)))
      isX = !isX
    }

    return newPoints
  }

  function updateTooltip(tooltip, x, y, text, subline) {
    const i18nText = i18nService.translate(locale, text)
    const i18nSubline = subline ? `\n${i18nService.translate(locale, subline)}` : ''

    tooltip.children[1].text(i18nText+i18nSubline);
    tooltip.position({ x: x, y: y });
    if (text) tooltip.show();
  };

  const opacities = {
    tooltip: 1,
    tooltipShadow: 0.25,
    editable: 1,
    regular: 1,
    background: 0.25
  }

  // initialize konva
  let width = canvasPlaceholderRef.current.clientWidth;
  let height = canvasPlaceholderRef.current.clientHeight;

  panelRef.current.style.height = `${canvasPlaceholderRef.current.clientHeight}px`
  setDynamicHeight(canvasPlaceholderRef.current.clientHeight)

  stage = new Konva.Stage({
    container: 'konva-container',
    width: width,
    height: height,
    editable: false,
    name: 'stage'
  });

  backgroundLayer = new Konva.Layer({ editable: false, name: 'layer' });
  shapesLayer = new Konva.Layer({ editable: false, name: 'layer' });
  tooltipLayer = new Konva.Layer({ editable: false, name: 'layer' });


  // =========================================================================
  // INITIALIZE ZOOM
  // =========================================================================

  stage.add(backgroundLayer);

  // main API:
  const konvaContainer = document.querySelector('#konva-container');
  var imageObj = new Image();
  imageObj.onload = function () {
    var canvasBackground = new Konva.Image({
      x: 0,
      y: 0,
      image: imageObj,
      width: konvaContainer.clientWidth,
      height: konvaContainer.clientHeight,
    });

    // add the shape to the layer
    backgroundLayer.add(canvasBackground);
    backgroundLayer.batchDraw();
  };
  if (backgroundImageUrl) imageObj.src = backgroundImageUrl;


  // =========================================================================
  // INITIALIZE TOOLTIPS
  // =========================================================================

  let tooltip = new Konva.Label({
    opacity: opacities.tooltip,
    visible: false,
    listening: false,
    name: 'label'
  });

  tooltip.add(
    new Konva.Tag({
      fill: '#313131',
      pointerDirection: 'down',
      pointerWidth: 20,
      pointerHeight: 10,
      cornerRadius: 4,
      lineJoin: 'round',
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: opacities.tooltipShadow,
      name: 'tag'
    })
  );

  tooltip.add(
    new Konva.Text({
      text: '',
      align: 'center',
      lineHeight: 2,
      fontFamily: 'Inter',
      fontSize: 13,
      padding: 5,
      fill: 'white',
      name: 'tag',
      width: wideTooltip ? 160 : 80
    })
  );

  tooltipLayer.add(tooltip);

  function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  function getCenter(p1, p2) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }
  var lastCenter = null;
  var lastDist = 0;

  stage.on('touchmove', function (e) {
    e.evt.preventDefault();

    // IMPORTANT:
    // if the konva-container unit has a background image - remove it
    const konvaContainer = document.querySelector('#konva-container')
    if (konvaContainer.style.backgroundImage) konvaContainer.style.backgroundImage = ''

    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];

    if (touch1 && touch2) {
      // if the stage was under Konva's drag&drop
      // we need to stop it, and implement our own pan logic with two pointers
      if (stage.isDragging()) {
        stage.stopDrag();
      }

      var p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };
      var p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      if (!lastCenter) {
        lastCenter = getCenter(p1, p2);
        return;
      }
      var newCenter = getCenter(p1, p2);

      var dist = getDistance(p1, p2);

      if (!lastDist) {
        lastDist = dist;
      }

      // local coordinates of center point
      var pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX(),
      };

      var scale = stage.scaleX() * (dist / lastDist);

      stage.scaleX(scale);
      stage.scaleY(scale);

      // calculate new position of the stage
      var dx = newCenter.x - lastCenter.x;
      var dy = newCenter.y - lastCenter.y;

      var newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      };

      stage.position(newPos);
      stage.batchDraw();

      stageScale = scale
      lastDist = dist;
      lastCenter = newCenter;
    }
  });

  stage.on('touchend', function () {
    if (stageScale < 1) {
      stage.scaleX(1);
      stage.scaleY(1);
      stage.position(0, 0);
      stage.batchDraw();
    }

    lastDist = 0;
    lastCenter = null;
  });

  // draw areas
  for (let key in areas) {
    let area = areas[key];
    let points = transformPoints(area.points);
    const areaEditable = area.editable;

    let shape = new Konva.Line({
      stroke: 'white',
      strokeWidth: 2,
      points: points,
      fill: area.color,
      subline: area.subline,
      opacity: area.alwaysVisible ? 1 : 0,
      closed: true,
      editable: areaEditable,
      key: key,
      perfectDrawEnabled: false,
      name: 'line'
    });

    function onClickOrTap() {
      if (!area.disabled) touchAction(area[touchActionProperty])
    }

    shape.on('click', function(evt) { onClickOrTap() })

    // eslint-disable-next-line
    shape.on('tap', function() {
      if (parseInt(shape.getAttr('opacity')) > 0) {
        onClickOrTap()
      } else {
        // TODO: set all other shape opacities to 0
        for (let group of shapesLayer.getChildren()) {
          // every group contains a line that needs to be hidden
          const s = group.getChildren()[0]
          s.opacity((areas[s.attrs.key] === undefined || areas[s.attrs.key].alwaysVisible) ? 1 : 0);
        }

        shape.opacity(opacities.regular);
        shapesLayer.draw();
      }
    })

    // eslint-disable-next-line
    shape.on('mouseenter', function() {
      if (!area.disabled) stage.container().style.cursor = 'pointer';
    });

    // eslint-disable-next-line
    shape.on('mouseleave', function() {
      stage.container().style.cursor = 'default';
    });

    let group = new Konva.Group({
      name: 'group',
      draggable: areaEditable,
      editable: areaEditable
    })

    shapesLayer.add(group);
    group.add(shape);
  }

  stage.add(shapesLayer);
  stage.add(tooltipLayer);

  stage.on('mouseover', function(evt) {
    let shape = evt.target;

    if (shape && !shape.attrs.editable) {
      shape.opacity(opacities.regular);
      shapesLayer.draw();
    }
  });

  stage.on('mouseout', function(evt) {
    let shape = evt.target;
    if (shape && !shape.attrs.editable) {
      shape.opacity((areas[shape.attrs.key] === undefined || areas[shape.attrs.key].alwaysVisible) ? 1 : 0);
      shapesLayer.draw();
      tooltip.hide();
      tooltipLayer.draw();
    }
  });

  stage.on('mousemove', function(evt) {
    let shape = evt.target;
    if (shape && stage.getPointerPosition()) {
      let mousePos = stage.getPointerPosition();
      let x = mousePos.x;
      let y = mousePos.y - 5;
      updateTooltip(tooltip, x, y, shape.attrs.key, shape.attrs.subline);
      tooltipLayer.batchDraw();
    }
  });
}
