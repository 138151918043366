import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Select from 'react-select'

import i18nService from '../../helpers/i18nService';
import eventService from '../../helpers/eventService';

import ProjectList from '../../components/ProjectList/ProjectList';
import ConditionalColumnSpan from '../../components/ConditionalColumnSpan';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import useWindowSize from '../../hooks/useWindowSize/useWindowSize';

import initializeCanvas from '../../helpers/initializeCanvas';

import { ChevronLeft } from 'react-feather';

import Env from '../../Environments';

export default function Group(props) {
  const [group, setGroup] = useState({})
  const [projects, setProjects] = useState([])
  const [fetchedProjects, setFetchedProjects] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)
  const [dynamicHeight, setDynamicHeight] = useState()
  const [tableSorting, setTableSorting] = useState({
    property: 'name', asc: true
  })
  const [filterByProject, setFilterByProject] = useState()
  const [highlightProject, setHighlightProject] = useState()

  const [width, height] = useWindowSize();

  const canvasAreaRef = useRef(null)
  const canvasPlaceholderRef = useRef(null)
  const panelRef = useRef(null)
  const filterRow = useRef(null)

  useEffect(() => {
    async function fetchGroup() {
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': props.token
        },
        url: `${Env.url}/api/v1/groups/${props.groupId}`
      };

      const response = await axios(options)

      setGroup(response.data)

      let groupProjects = []
      for (let project of response.data.projects) {
        const r2 = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': props.token
          },
          url: `${Env.url}/api/v1/projects/${project}`
        })

        groupProjects.push(r2.data)

        setProjects(groupProjects)
        props.setIsLoading(false)
      }

      setFetchedProjects(true)
    }

    if (props.token && props.groupId && !initialLoad) {
      fetchGroup()
      setInitialLoad(true)
    }

    function tapAndOpenProject(project) {
      eventService.logEvent('project', project, 'view', 'from canvas')

      props.selectProject(project)
    }

    function reinitializeCanvas() {
      if (props.groupId && canvasPlaceholderRef.current && canvasPlaceholderRef.current.clientWidth !== 0 && canvasPlaceholderRef.current.clientHeight !== 0) {
        initializeCanvas(
          canvasPlaceholderRef,
          panelRef,
          setDynamicHeight,
          getData(),
          group,
          tapAndOpenProject,
          'project',
          false,
          props.locale,
          group.groupImageUrl
        )
      } else { setTimeout(reinitializeCanvas, 1000) }
    }

    function getData() {
      // initialize placeholder
      const placeholderWidth = 700 // base width
      const placeholderHeight = canvasPlaceholderRef.current.clientHeight*(700/canvasPlaceholderRef.current.clientWidth)

      let areas = {}
      for (let project of projects) {
        const coordinates = JSON.parse(project.path || '[]')
        const absolute = coordinates.map((e, i) => {
          if (i % 2) {
            // Y coordinate
            return placeholderWidth * e
          } else {
            return placeholderHeight * e
          }
        })

        areas[project.name] = {
          editable: false,
          project: project,
          subline: '',
          color: project.accentColor || 'rgba(15, 33, 49, 0.65)',
          points: absolute,
          alwaysVisible: project === highlightProject
        }
      }

      return areas;
    }

    if (projects.length) reinitializeCanvas()
  }, [width, height, group, projects, props.groupId, fetchedProjects, initialLoad, props, highlightProject]);

  function setSortingBy(property) {
    setTableSorting({
      property: property,
      asc: property === tableSorting.property ? !tableSorting.asc : 0
    })
  }

  function clickAndOpenProject(project) {
    eventService.logEvent('project', project, 'view', 'from project list')

    props.selectProject(project)
  }

  let canvasBreakpoints = [12, 12, 7, 8];
  let sidebarBreakpoints = [12, 12, 5, 4];

  if (props.locale === 'de') {
    canvasBreakpoints = [12, 12, 6, 7];
    sidebarBreakpoints = [12, 12, 6, 5];
  }

  if (props.locale === 'es' || props.locale === 'fr') {
    canvasBreakpoints = [12, 12, 6, 7];
    sidebarBreakpoints = [12, 12, 6, 5];
  }

  const icon = (state) => ({
    alignItems: 'center',
    display: 'flex',

    ':after': {
      color: '#2358e3',
      verticalAlign: 'top',
      content: state.isSelected ? '"L"' : '""',
      display: 'inline-block',
      position: 'absolute',
      marginRight: 10,
      right: 0,
      height: 16,
      width: 16,
      fontFamily: 'arial',
      transform: 'scaleX(-1) rotate(-35deg)'
    },
  });

  const customSelectStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        ...icon(state),
        cursor: 'pointer',
        background: 'none !important',
        backgroundColor: 'none !important',
        boxShadow: 'none',
        color: state.isSelected ? '#2358e3' : '#222',
        fontWeight: state.isSelected ? '600' : '500'
      }
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: '#495057',
        fontWeight: '600',
        lineHeight: '1.5',
        fontSize: '0.875rem'
      }
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: '#495057',
        fontWeight: '600',
        lineHeight: '1.5',
        fontSize: '0.875rem'
      }
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: '999 !important',
        borderRadius: '5px',
        background: 'white !important',
        backgroundColor: 'white !important'
      }
    },
    indicatorsContainer: (provided, state) => {
      return {
        ...provided,
        display: 'none'
      }
    },
    control: (provided, state) => {
      return {
        ...provided,
        paddingTop: '22px',
        paddingBottom: '10px',
        border: 'none',
        background: 'none',
        outline: 'none',
        boxShadow: 'none',
        cursor: 'pointer'
      }
    },
    container: (provided, state) => {
      return {
        ...provided,
        zIndex: '999 !important',
        background: 'none',
        cursor: 'pointer'
      };
    }
  }

  const availableProjectTypes = Array.from(new Set(projects.map(p => p.projectType)))

  let projectFilter;
  if (projects) {
    projectFilter = (
      <ConditionalColumnSpan containerRef={filterRow}>
        <Form.Group controlId="unitForm.SelectCustom" className="table-filter">
          <Form.Label>{i18nService.i18n(props.locale, 'globals.pre_choose')} {i18nService.i18n(props.locale, 'group.projects')} {i18nService.i18n(props.locale, 'globals.post_choose')}</Form.Label>
          <Select
            styles={customSelectStyles}
            classNamePrefix="react-select"
            options={[
              {
                value: undefined,
                label: i18nService.i18n(props.locale, 'group.all_projects')
              },
              ...availableProjectTypes.map(b => (
                {
                  value: b,
                  label: i18nService.translate(props.locale, b)
                }
              ))
            ]}
            isSearchable={false}
            isClearable={false}
            hideSelectedOptions={false}
            controlShouldRenderValue={true}
            isMulti={false}
            placeholder={i18nService.i18n(props.locale, 'group.all_projects')}
            onChange={e => setFilterByProject(e.value)}
            />
        </Form.Group>
      </ConditionalColumnSpan>
    )
  }

  let projectFilterRow;
  if (availableProjectTypes.length > 1) {
    projectFilterRow = <Row ref={filterRow}>{projectFilter}</Row>
  }

  let conditionalBackNav;
  if (props.collectionId) {
    conditionalBackNav = (
      <div className="panel-navigation" onClick={() => props.setSelectedGroupId()}>
        <ChevronLeft color='white' />
      </div>
    )
  }

  return (
    <div className="Exterior overflow-hidden" ref={panelRef}>
      <Row className="h-100">
        <Col xs={canvasBreakpoints[0]} md={canvasBreakpoints[1]} lg={canvasBreakpoints[2]} xl={canvasBreakpoints[3]} className="position-relative px-0">
          {conditionalBackNav}

          <img
            className='main-image'
            ref={canvasPlaceholderRef}
            alt={group.name}
            style={{width: ((canvasAreaRef.current || {}).clientWidth || 1), height: 'auto'}}
            src={group.groupImageUrl}
            />

          <div
            id="konva-container"
            ref={canvasAreaRef}
            style={group.groupImageUrl ? {backgroundImage: `url('${group.groupImageUrl}')`} : {}}
            />
        </Col>

        <Col xs={sidebarBreakpoints[0]} md={sidebarBreakpoints[1]} lg={sidebarBreakpoints[2]} xl={sidebarBreakpoints[3]} className="mh-100 planpoint-sidebar">
          <div className="px-2 py-4">
            <Form>
              {projectFilterRow}
              <Row>
                <Col>
                  <ProjectList
                    locale={props.locale}
                    group={group}
                    projects={projects}
                    filterByProject={filterByProject}
                    selectProject={project => clickAndOpenProject(project)}
                    tableSorting={tableSorting}
                    setSortingBy={prop => setSortingBy(prop)}
                    dynamicHeight={dynamicHeight}
                    setHighlightProject={p => setHighlightProject(p)}
                    />
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}
