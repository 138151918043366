import React, { useEffect, useState } from 'react';

import Collection from './views/Collection';
import GroupOrProject from './views/GroupOrProject';

import './App.css';

function App() {
  const [token, setToken] = useState()
  const [projectId, setProjectId] = useState()
  const [groupId, setGroupId] = useState()
  const [collectionId, setCollectionId] = useState()
  const [locale, setLocale] = useState()

  const [selectedGroupId, setSelectedGroupId] = useState()
  const [selectedProjectId, setSelectedProjectId] = useState()

  useEffect(() => {
    const ppRoot = document.querySelector('#planpoint-root')

    setToken(ppRoot.getAttribute('data-token'))
    setProjectId(ppRoot.getAttribute('data-project'))
    setGroupId(ppRoot.getAttribute('data-group'))
    setCollectionId(ppRoot.getAttribute('data-collection'))
    setLocale(ppRoot.getAttribute('data-lang') || 'en')
  }, [setToken, projectId, groupId, locale])

  if (collectionId) {
    if (selectedGroupId) {
      return (
        <GroupOrProject
          token={token}
          collectionId={collectionId}
          groupId={selectedGroupId}
          locale={locale}
          setSelectedGroupId={e => setSelectedGroupId(e)}
        />
      )
    } else if (selectedProjectId) {
      return (
        <GroupOrProject
          token={token}
          collectionId={collectionId}
          projectId={selectedProjectId}
          locale={locale}
          setSelectedProjectId={e => setSelectedProjectId(e)}
        />
      )
    } else {
      return (
        <Collection
          token={token}
          collectionId={collectionId}
          setSelectedGroupId={e => setSelectedGroupId(e)}
          setSelectedProjectId={e => setSelectedProjectId(e)}
          />
      )
    }
  } else {
    return (
      <GroupOrProject
        token={token}
        projectId={projectId}
        groupId={groupId}
        locale={locale}
      />
    )
  }
}

export default App;
