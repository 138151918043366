import React from 'react';
import Select from 'react-select';

export default function CustomSelect(props) {
  const icon = (state) => ({
    alignItems: 'center',
    display: 'flex',

    ':after': {
      color: '#2418ab',
      verticalAlign: 'top',
      content: state.isSelected ? '"L"' : '""',
      display: 'inline-block',
      position: 'absolute',
      marginRight: 10,
      right: 0,
      height: 16,
      width: 16,
      fontFamily: 'arial',
      transform: 'scaleX(-1) rotate(-35deg)'
    },
  });

  const customSelectStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        ...icon(state),
        cursor: 'pointer',
        background: 'none !important',
        backgroundColor: 'none !important',
        boxShadow: 'none',
        color: state.isSelected ? '#2418ab' : '#222',
        fontWeight: state.isSelected ? '600' : '500'
      }
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: '#888',
        fontWeight: '600',
        lineHeight: '1.5',
        fontSize: '0.875rem'
      }
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: '#495057',
        fontWeight: '600',
        lineHeight: '1.5',
        fontSize: '0.875rem'
      }
    },
    multiValue: (provided, state) => {
      return {
        ...provided,
        color: '#fff',
        fontWeight: '600',
        lineHeight: '1.5',
        fontSize: '0.875rem',
        background: '#2418ab'
      }
    },
    multiValueLabel: (provided, state) => {
      return {
        ...provided,
        color: '#fff',
        
      }
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: '999 !important',
        borderRadius: '0',
        background: 'white !important',
        backgroundColor: 'white !important'
      }
    },
    indicatorsContainer: (provided, state) => {
      return {
        ...provided,
        display: 'none'
      }
    },
    control: (provided, state) => {
      return {
        ...provided,
        border: 'none',
        borderBottom: '2px solid #222 !important',
        background: 'none',
        outline: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
        borderRadius: '0'
      }
    },
    container: (provided, state) => {
      return {
        ...provided,
        zIndex: '999 !important',
        background: 'none',
        cursor: 'pointer'
      };
    },
    valueContainer: (provided, state) => {
      return {
        ...provided,
        padding: '2px 4px'
      };
    }
  }

  return (
    <Select
      styles={customSelectStyles}
      classNamePrefix="react-select"
      value={props.value}
      options={props.options}
      isSearchable={props.isSearchable}
      isClearable={props.isClearable}
      hideSelectedOptions={props.hideSelectedOptions}
      isMulti={props.isMulti}
      placeholder={props.placeholder}
      onChange={e => props.onChange(e)}
      />
  )
}