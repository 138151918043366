import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select'

import i18nService from '../../helpers/i18nService';
import eventService from '../../helpers/eventService';

import Branding from '../../components/Branding/Branding';
import MinMaxIcon from '../../components/MinMaxIcon/MinMaxIcon';
import UnitList from '../../components/UnitList/UnitList';
import ShortlistMenu from '../../components/ShortlistMenu/ShortlistMenu';
import RangeSliderRow from '../../components/RangeSliderRow';
import ConditionalColumnSpan from '../../components/ConditionalColumnSpan';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import useWindowSize from '../../hooks/useWindowSize/useWindowSize';

import initializeCanvas from '../../helpers/initializeCanvas';

import backArrow from '../../assets/back-arrow.svg';
import gridIconInactive from '../../assets/grid_view_icon_inactive.svg';
import gridIconActive from '../../assets/grid_view_icon_active.svg';
import listIconInactive from '../../assets/list_view_icon_inactive.svg';
import listIconActive from '../../assets/list_view_icon_active.svg';

import Env from '../../Environments';

export default function Floorplan(props) {
  const [canvasInit, setCanvasInit] = useState(false)
  const [project, setProject] = useState()
  const [floor, setFloor] = useState()
  const [floors, setFloors] = useState([])
  const [units, setUnits] = useState([])
  const [filterByFloor, setFilterByFloor] = useState()
  const [filterByBedrooms, setFilterByBedrooms] = useState()
  const [filterByPrice, setFilterByPrice] = useState()
  const [filterByArea, setFilterByArea] = useState()
  const [gridView, setGridView] = useState(false)
  const [dynamicHeight, setDynamicHeight] = useState()
  const [tableSorting, setTableSorting] = useState({
    property: 'name', asc: true
  })
  const [width, height] = useWindowSize();
  const [highlightUnit, setHighlightUnit] = useState()

  const canvasAreaRef = useRef(null)
  const canvasPlaceholderRef = useRef(null)
  const panelRef = useRef(null)

  const filterRow = useRef(null)

  let canvasBreakpoints = [12, 12, 7, 8];
  let sidebarBreakpoints = [12, 12, 5, 4];

  if (props.locale === 'de') {
    canvasBreakpoints = [12, 12, 6, 7];
    sidebarBreakpoints = [12, 12, 6, 5];
  }

  if (props.locale === 'es' || props.locale === 'fr') {
    canvasBreakpoints = [12, 12, 6, 7];
    sidebarBreakpoints = [12, 12, 6, 5];
  }

  let floorScope = 'floor';
  if (project && (project.previewMode || project.projectType === 'land')) {
    floorScope = 'phase'
  }

  useEffect(() => {
    if (props.selectedProject && props.selectedProject !== project) setProject(props.selectedProject)
    if (props.floors && props.floors !== floors) setFloors(props.floors)

    if (floors.length && !floor) {
      for (let f of props.floors) {
        if (f._id === props.floor._id) {
          setFloor(f)
          setFilterByFloor(props.floor._id)
        }
      }
    }

    if (floor && !units.length) {
      let currentFloor = props.floors.filter(f => f._id === floor._id)[0]
      if (currentFloor.units) setUnits(currentFloor.units)
    }

    function getData() {
      // initialize placeholder
      const placeholderWidth = 700 // base width
      const placeholderHeight = canvasPlaceholderRef.current.clientHeight*(placeholderWidth/canvasPlaceholderRef.current.clientWidth)

      let areas = {}
      for (let unit of units) {
        const coordinates = JSON.parse(unit.path || '[]')
        const absolute = coordinates.map((e, i) => {
          if (i % 2) {
            // Y coordinate
            return placeholderWidth * e
          } else {
            return placeholderHeight * e
          }
        })

        const unitSold = unit.availability === 'Sold' || unit.availability === 'Leased' || unit.availability === 'Unavailable'
        let unitColor;
        if (unitSold) {
          unitColor = 'rgba(227, 41, 35, 0.2)'
        } else if (unit.availability === 'Reserved') {
          unitColor = 'rgba(0, 0, 0, 0.25)'
        } else {
          unitColor = project.accentColor || 'rgba(15, 33, 49, 0.65)'
        }

        let subline = `${unit.squareFeet} ${i18nService.i18n(props.locale, 'globals.ft²')} | ${unit.bedrooms}`
        if (project && project.landOnly) {
          if (project.showPrices) {
            subline = `${unit.squareFeet} ${i18nService.i18n(props.locale, 'globals.ft²')} | ${unit.price ? (unit.price).toLocaleString(`${props.locale.match('showcase') ? 'en' : props.locale}-CA`, { style: 'currency', currency: 'CAD', minimumFractionDigits: 0 }).replace(/[A-Z]/g, '') : '?'}`
          } else {
            subline = `${unit.squareFeet} ${i18nService.i18n(props.locale, 'globals.ft²')}`
          }
        }

        const unitNotAvailable = unit.availability && (unit.availability !== 'Available')
        const unitIsHighlighted = unit._id && (unit._id === (highlightUnit || {})._id)

        areas[unit.name] = {
          editable: false,
          unit: unit,
          subline: subline,
          disabled: unitSold,
          color: unitColor,
          points: absolute,
          alwaysVisible: unitNotAvailable || unitIsHighlighted
        }
      }

      return areas;
    }

    function goToUnit(unit) {
      eventService.logEvent('unit', unit, 'view', 'from canvas')

      props.selectUnit(unit)
    }

    function reinitializeCanvas() {
      if (project && floor && canvasPlaceholderRef.current && canvasPlaceholderRef.current.clientWidth !== 0 && canvasPlaceholderRef.current.clientHeight !== 0) {
        initializeCanvas(canvasPlaceholderRef, panelRef, setDynamicHeight, getData(), project, goToUnit, 'unit', true, props.locale, floor.floorplanUrl)
      } else {
        // try again in a second (wait for the project image to render fully)
        setTimeout(function() { reinitializeCanvas() }, 1000)
      }
    }

    if (floor && !canvasInit && units.length) {
      reinitializeCanvas()
      setCanvasInit(true)
    }
  }, [width, height, floor, project, highlightUnit, props, floors, units, canvasInit]);

  function setSortingBy(property) {
    setTableSorting({
      property: property,
      asc: property === tableSorting.property ? !tableSorting.asc : 0
    })
  }

  function selectFloor(newFloor) {    
    if (newFloor) {
      setFilterByFloor(newFloor._id)

      setFloor(newFloor)
      setUnits(newFloor.units)

      props.selectFloor(newFloor, false)
      setCanvasInit(false)
    } else {
      props.selectExterior()
    }
  }

  const icon = (state) => ({
    alignItems: 'center',
    display: 'flex',

    ':after': {
      color: '#2358e3',
      verticalAlign: 'top',
      content: state.isSelected ? '"L"' : '""',
      display: 'inline-block',
      position: 'absolute',
      marginRight: 10,
      right: 0,
      height: 16,
      width: 16,
      fontFamily: 'arial',
      transform: 'scaleX(-1) rotate(-35deg)'
    },
  });

  const customSelectStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        ...icon(state),
        cursor: 'pointer',
        background: 'none !important',
        backgroundColor: 'none !important',
        boxShadow: 'none',
        color: state.isSelected ? '#2358e3' : '#222',
        fontWeight: state.isSelected ? '600' : '500'
      }
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: '#495057',
        fontWeight: '600',
        lineHeight: '1.5',
        fontSize: '0.875rem'
      }
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: '#495057',
        fontWeight: '600',
        lineHeight: '1.5',
        fontSize: '0.875rem'
      }
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: '999 !important',
        borderRadius: '5px',
        background: 'white !important',
        backgroundColor: 'white !important'
      }
    },
    indicatorsContainer: (provided, state) => {
      return {
        ...provided,
        display: 'none'
      }
    },
    control: (provided, state) => {
      return {
        ...provided,
        paddingTop: '22px',
        paddingBottom: '10px',
        border: 'none',
        background: 'none',
        outline: 'none',
        boxShadow: 'none',
        cursor: 'pointer'
      }
    },
    container: (provided, state) => {
      return {
        ...provided,
        zIndex: '999 !important',
        background: 'none',
        cursor: 'pointer'
      };
    }
  }

  let bedroomFilter;
  if (project && !project.previewMode && project.projectType !== 'commercial') {
    bedroomFilter = (
      <ConditionalColumnSpan containerRef={filterRow}>
        <Form.Group controlId="unitForm.SelectCustom" className="table-filter">
          <Form.Label>{i18nService.i18n(props.locale, 'globals.pre_choose')} {i18nService.i18n(props.locale, 'floorplan.bedrooms')} {i18nService.i18n(props.locale, 'globals.post_choose')}</Form.Label>
          <Select
            styles={customSelectStyles}
            classNamePrefix="react-select"
            options={[{ value: undefined, label: i18nService.i18n(props.locale, 'exterior.all_bedrooms') }, ...props.availableBedrooms.map(b => ({ value: b, label: i18nService.translate(props.locale, b) }))]}
            isSearchable={false}
            isClearable={false}
            hideSelectedOptions={false}
            isMulti={false}
            placeholder={i18nService.i18n(props.locale, 'exterior.all_bedrooms')}
            onChange={e => setFilterByBedrooms(e.value)}
            />
        </Form.Group>
      </ConditionalColumnSpan>
    )
  }

  let floorFilter;
  if (floor) {
    floorFilter = (
      <ConditionalColumnSpan containerRef={filterRow}>
        <Form.Group controlId="unitForm.SelectCustom" className="table-filter">
          <Form.Label>{i18nService.i18n(props.locale, 'globals.pre_choose')} {i18nService.i18n(props.locale, `floorplan.${floorScope}`)} {i18nService.i18n(props.locale, 'globals.post_choose')}</Form.Label>
          <Select
            styles={customSelectStyles}
            options={[{ value: undefined, label: i18nService.translate(props.locale, `${floorScope} 1 - ${floors.length}`) }, ...floors.map(f => ({ value: f, label: i18nService.translate(props.locale, f.name) }))]}
            isSearchable={false}
            isClearable={false}
            hideSelectedOptions={false}
            isMulti={false}
            placeholder={i18nService.translate(props.locale, floor.name)}
            onChange={e => selectFloor(e.value)}
            />
        </Form.Group>
      </ConditionalColumnSpan>
    )
  }

  let rangeSliderOptions;
  if (project && (project.showPriceFilter || project.showAreaFilter)) {
    rangeSliderOptions = (
      <RangeSliderRow
        locale={props.locale}
        project={project}
        units={units}
        doneLoading={true}
        setFilterByPrice={v => setFilterByPrice(v)}
        setFilterByArea={v => setFilterByArea(v)}
        />
    )
  }

  function updateHighlightUnit(u) {
    setHighlightUnit(u)
    setCanvasInit(false)
  }

  return (
    <div className="Exterior overflow-hidden" ref={panelRef}>
      <Row className="h-100">
        <Col xs={canvasBreakpoints[0]} md={canvasBreakpoints[1]} lg={canvasBreakpoints[2]} xl={canvasBreakpoints[3]} className="position-relative px-0">
          <div className="panel-navigation" onClick={() => props.selectExterior()}>
            <img alt="go-back" src={`${Env.url}${backArrow}`} />
          </div>

          <Branding target={canvasPlaceholderRef.current} showBranding={project && project.showBranding}/>

          <img
            className='main-image'
            ref={canvasPlaceholderRef}
            alt={(floor || {}).name}
            style={{width: ((canvasAreaRef.current || {}).clientWidth || 1), height: 'auto'}}
            src={(floor || {}).floorplanUrl}
            />

          <div
            id="konva-container"
            ref={canvasAreaRef}
            style={(floor || {}).floorplanUrl ? {backgroundImage: `url('${(floor || {}).floorplanUrl}')`} : {}}
            />

          <ShortlistMenu
            selectUnit={unit => props.selectUnit(unit)}
            project={project}
            token={props.token}
            locale={props.locale}
            />
        </Col>

        <Col xs={sidebarBreakpoints[0]} md={sidebarBreakpoints[1]} lg={sidebarBreakpoints[2]} xl={sidebarBreakpoints[3]} className="mh-100 planpoint-sidebar">
          <div className="px-2 py-4">
            <Form>
              <Row ref={filterRow}>
                {bedroomFilter}
                {floorFilter}

                <Col xs={12} md={4} className="d-flex flex-row flex-nowrap align-items-end justify-content-start">
                  <Form.Group>
                    <img
                      className="grid-list-icon-list mr-2"
                      alt="grid-list-icon-grid"
                      src={`${Env.url}${gridView ? listIconInactive : listIconActive}`}
                      onClick={() => setGridView(false)}
                      />
                    <img
                      className="grid-list-icon-list mr-2"
                      alt="grid-list-icon-list"
                      src={`${Env.url}${gridView ? gridIconActive : gridIconInactive}`}
                      onClick={() => setGridView(true)}
                      />
                    <MinMaxIcon />
                  </Form.Group>
                </Col>
              </Row>

              {rangeSliderOptions}

              <Row>
                <Col>
                  <UnitList
                    locale={props.locale}
                    project={project}
                    units={units}
                    filterByFloor={filterByFloor}
                    filterByBedrooms={filterByBedrooms}
                    filterByPrice={filterByPrice}
                    filterByArea={filterByArea}
                    selectUnit={unit => props.selectUnit(unit)}
                    tableSorting={tableSorting}
                    setSortingBy={prop => setSortingBy(prop)}
                    gridView={gridView}
                    dynamicHeight={dynamicHeight}
                    hoverAction={unit => updateHighlightUnit(unit)}
                    />
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}
