import React, { useState } from 'react';

import Lightbox from 'react-image-lightbox';

export default function LightboxExample(props) {
  const [photoIndex, setPhotoIndex] = useState(0);

  if (props.isOpen) {
    return (
      <Lightbox
        mainSrc={props.items[photoIndex]}
        nextSrc={props.items[(photoIndex + 1) % props.items.length]}
        prevSrc={props.items[(photoIndex + props.items.length - 1) % props.items.length]}
        onCloseRequest={props.onClose}
        onMovePrevRequest={() => setPhotoIndex((photoIndex + props.items.length - 1) % props.items.length)}
        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % props.items.length)}
      />
    )
  } else {
    return <div></div>
  }
}
