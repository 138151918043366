import React, { useState } from 'react';

import { ChevronDown } from 'react-feather';

import i18nService from '../../helpers/i18nService';

import { Scrollbars } from 'react-custom-scrollbars';

export default function ProjectList(props) {
  const [verticalThumbStyle, setVerticalThumbStyle] = useState(0)

  let sortingIcon = (
    <ChevronDown
      size={16}
      alt="sort-by"
      className={`ml-1 ${props.tableSorting.asc ? '' : 'icon-flip-v'}`}
      />
  )

  function projectComparison(a, b) {
    const aValue = a[props.tableSorting.property]
    const bValue = b[props.tableSorting.property]

    const validNumberA = !isNaN(aValue) && !isNaN(parseFloat(aValue))
    const validNumberB = !isNaN(bValue) && !isNaN(parseFloat(bValue))

    if (props.tableSorting.asc) {
      if (validNumberA && validNumberB) {
        return (parseInt(aValue) > parseInt(bValue)) ? 1 : -1
      } else {
        return (aValue > bValue) ? 1 : -1
      }
    } else {
      if (validNumberA && validNumberB) {
        return (parseInt(aValue) < parseInt(bValue)) ? 1 : -1
      } else {
        return (aValue < bValue) ? 1 : -1
      }
    }
  }

  let availableProjects = props.projects
  if (props.filterByProject) {
    availableProjects = availableProjects.filter(p => p.projectType === props.filterByProject)
  }

  let projectList = availableProjects.sort(projectComparison).map(project => (
    <tr
      className="unit-row"
      key={`project-${project._id}`}
      onClick={() => props.selectProject(project)}
      onMouseEnter={() => props.setHighlightProject(project)}
      onMouseLeave={() => props.setHighlightProject()}
      >
      <td>{project.name}</td>
      {/* <td>{(project.projectType || 'Sales').replace(/^\w/, c => c.toUpperCase())}</td> */}
      <td>{project.floors.length}</td>
    </tr>
  ))

  function handleScrollFrame(values) {
    setVerticalThumbStyle((props.dynamicHeight - 97 - 52) * values.top)
  }

  function renderThumbVertical({ style, ...props }) {
    const customStyle = { top: `${verticalThumbStyle}px` }
    return (
      <div {...props} style={{ ...style, ...customStyle }} className="thumb-vertical"/>
    )
  }

  let scrollbarStyles;
  if (props.dynamicHeight) {
    scrollbarStyles = { height: (props.dynamicHeight - 97) || 0 }
  } else {
    scrollbarStyles = { minHeight: '300px' }
  }

  return (
    <Scrollbars
      className="custom-scrollbar"
      style={scrollbarStyles}
      renderTrackVertical={props => <div {...props} className="track-vertical"/>}
      renderThumbVertical={renderThumbVertical}
      onScrollFrame={values => handleScrollFrame(values)}
      >
      <table className="table">
        <thead>
          <tr>
            <th
              scope="col"
              onClick={() => props.setSortingBy('name')}
              >
              <span className="d-flex flex-row flex-nowrap align-items-center justify-content-start">
                {i18nService.i18n(props.locale, 'projectList.project')} {props.tableSorting.property === 'name' ? sortingIcon : ''}
              </span>
            </th>
            {/* <th
              scope="col"
              onClick={() => props.setSortingBy('projectType')}
              >
              <span className="d-flex flex-row flex-nowrap align-items-center justify-content-start">
                {i18nService.i18n(props.locale, 'projectList.projectType')} {props.tableSorting.property === 'projectType' ? sortingIcon : ''}
              </span>
            </th> */}
            <th
              scope="col"
              onClick={() => props.setSortingBy('floors')}
              >
              <span className="d-flex flex-row flex-nowrap align-items-center justify-content-start">
                {i18nService.i18n(props.locale, 'projectList.floors')} {props.tableSorting.property === 'floors' ? sortingIcon : ''}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {projectList}
        </tbody>
      </table>
    </Scrollbars>
  )
}
