import React, { useEffect, useState } from 'react';

import planpointLogo from '../../assets/powered-by-planpoint-v3.svg';

export default function Branding(props) {
  const [brandingStyle, setBrandingStyle] = useState({})

  useEffect(() => {
    setBrandingStyle({
      opacity: props.target ? 1 : 0,
      display: props.showBranding ? 'block' : 'none'
    })
  }, [props.target, props.showBranding])

  return (
    <a
      className="panel-branding"
      style={brandingStyle}
      href="https://www.planpoint.io"
      onClick="ga('send', 'event', 'Badge', 'click', 'Accueil');"
      target="_blank"
      rel="noreferrer"
      >
      <img alt="powered-by-planpoint" src={`https://planpoint-v1.herokuapp.com${planpointLogo}`} />
    </a>
  )
}
