import React, { useState } from 'react';

import Lightbox from 'react-image-lightbox';

export default function FinishesModal(props) {
  const [photoIndex, setPhotoIndex] = useState(0);

  function highlightCurrentOption(idx) {
    const optionBtns = document.querySelectorAll('.btn-finish-option')

    for (let i = 0; i < optionBtns.length; i++) {
      const btn = optionBtns[i]

      if (i === idx) {
        btn.classList.add('btn-finish-option--active')
      } else {
        btn.classList.remove('btn-finish-option--active')
      }
    }
  }

  function goToSlide(newIdx) {
    setPhotoIndex(newIdx)
    highlightCurrentOption(newIdx)
  }

  if (props.isOpen) {
    let fileName = props.items[photoIndex].split('/').pop().split('-')
    fileName.pop() // pop the last part

    let toolbarButtons = [];
    for (let i = 0; i < props.items.length; i++) {
      const item = props.items[i]

      let buttonLabel = item.split('/').pop().split('-')
      buttonLabel.pop()

      toolbarButtons.push(
        <button className="btn-finish-option" onClick={() => goToSlide(i)}>
          {buttonLabel}
        </button>
      )
    }

    return (
      <Lightbox
        mainSrc={props.items[photoIndex]}
        nextSrc={props.items[(photoIndex + 1) % props.items.length]}
        prevSrc={props.items[(photoIndex + props.items.length - 1) % props.items.length]}
        toolbarButtons={toolbarButtons}
        enableZoom={false}
        onAfterOpen={() => highlightCurrentOption(photoIndex)}
        onCloseRequest={props.onClose}
        onMovePrevRequest={() => goToSlide((photoIndex + props.items.length - 1) % props.items.length)}
        onMoveNextRequest={() => goToSlide((photoIndex + 1) % props.items.length)}
        imageCaption={fileName.join(' ')}
      />
    )
  } else {
    return <div></div>
  }
}
