import React, { useState } from 'react';

import { ChevronDown } from 'react-feather';

import i18nService from '../../helpers/i18nService';
import eventService from '../../helpers/eventService';

import Formatter from '../../helpers/formatter';

import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Scrollbars } from 'react-custom-scrollbars';

export default function UnitList(props) {
  const [verticalThumbStyle, setVerticalThumbStyle] = useState(0)

  function hoverAction(unit) {
    if (props.hoverAction) props.hoverAction(unit)
  }

  let sortingIcon = (
    <ChevronDown
      size={16}
      alt="sort-by"
      className={`ml-1 ${props.tableSorting.asc ? '' : 'icon-flip-v'}`}
      />
  )

  let priceHeader;
  if (props.project && props.project.showPrices) {
    let columnLabel = i18nService.i18n(props.locale, 'unitList.price')
    if (props.project.pricesStartingAt) columnLabel = i18nService.i18n(props.locale, 'unitList.price_from')

    priceHeader = (
      <th
        scope="col"
        onClick={() => props.setSortingBy('price')}
        >
        <span className="d-flex flex-row flex-nowrap align-items-center justify-content-start">
          {columnLabel} {props.tableSorting.property === 'price' ? sortingIcon : ''}
        </span>
      </th>
    )
  }

  let availabilityHeader;
  if (props.project && props.project.showAvailability) {
    availabilityHeader = (
      <th
        scope="col"
        onClick={() => props.setSortingBy('availability')}
        >
        <span className="d-flex flex-row flex-nowrap align-items-center justify-content-start">
          {i18nService.i18n(props.locale, 'unitList.availability')} {props.tableSorting.property === 'availability' ? sortingIcon : ''}
        </span>
      </th>
    )
  }

  let deliveryDateHeader;
  if (props.project && props.project.deliveryDates) {
    deliveryDateHeader = (
      <th
        scope="col"
        onClick={() => props.setSortingBy('deliveryDate')}
        >
        <span className="d-flex flex-row flex-nowrap align-items-center justify-content-start">
          {i18nService.i18n(props.locale, 'unitList.deliverydate')} {props.tableSorting.property === 'deliveryDate' ? sortingIcon : ''}
        </span>
      </th>
    )
  }

  function unitComparison(a, b) {
    const aValue = a[props.tableSorting.property]
    const bValue = b[props.tableSorting.property]

    const validNumberA = !isNaN(aValue) && !isNaN(parseFloat(aValue))
    const validNumberB = !isNaN(bValue) && !isNaN(parseFloat(bValue))

    if (props.tableSorting.asc) {
      if (validNumberA && validNumberB) {
        return (parseInt(aValue) > parseInt(bValue)) ? 1 : -1
      } else {
        return (aValue > bValue) ? 1 : -1
      }
    } else {
      if (validNumberA && validNumberB) {
        return (parseInt(aValue) < parseInt(bValue)) ? 1 : -1
      } else {
        return (aValue < bValue) ? 1 : -1
      }
    }
  }

  function trackAndClick(unit) {
    eventService.logEvent('unit', unit._id, 'view', 'from unit list')

    props.selectUnit(unit)
  }

  let unitList;
  let unitGrid;
  if (props.units) {
    let availableUnits = props.units.filter(u => u.availability !== 'Unavailable')

    if (props.project && props.project.hideSold) {
      availableUnits = availableUnits.filter(u => u.availability !== 'Sold' && u.availability !== 'Leased')
    }

    if (props.exclude) {
      availableUnits = availableUnits.filter(u => u !== props.exclude && u.availability !== 'Sold' && u.availability !== 'Leased')
    }

    if (props.prioritize && props.exclude) {
      const exclude = props.exclude

      let p1 = [] // 1) same number of bedrooms, same floor
      let p2 = [] // 2) same number of bedrooms different floor
      let p3 = [] // 3) same model # but different floor
      for (let unit of availableUnits) {
        if ((unit.bedrooms === exclude.bedrooms) && (unit.floor === exclude.floor)) {
          p1.push(unit)
        } else if (unit.bedrooms === exclude.bedrooms) {
          p2.push(unit)
        } else if (unit.type === exclude.type) {
          p3.push(unit)
        }
      }

      availableUnits = [...p1.sort(unitComparison), ...p2.sort(unitComparison), ...p3.sort(unitComparison)]
    } else {
      availableUnits = availableUnits.sort(unitComparison)
    }

    unitList = availableUnits.map(unit => {
      let unitRow;

      const bedroomFiltersApply = (!props.filterByBedrooms || props.filterByBedrooms === unit.bedrooms)
      const floorFiltersApply = (!props.filterByFloor || !props.filterByFloor.length || props.filterByFloor.indexOf(unit.floor) !== -1)
      const priceFiltersApply = !props.filterByPrice || (unit.price >= props.filterByPrice.from && unit.price <= props.filterByPrice.to)
      const areaFiltersApply = !props.filterByArea || (unit.squareFeet >= props.filterByArea.from && unit.squareFeet <= props.filterByArea.to)

      if (floorFiltersApply && bedroomFiltersApply && priceFiltersApply && areaFiltersApply) {
        let priceColumn;
        if (props.project && props.project.showPrices) {
          if (unit.availability === 'Sold' || unit.availability === 'Leased' || !unit.price) {
            priceColumn = <td>-</td>
          } else {
            const priceLocale = `${props.locale.match('showcase') ? 'en' : props.locale}-CA`
            const options = { style: 'currency', currency: 'CAD', minimumFractionDigits: 0 }
            const localPrice = unit.price.toLocaleString(priceLocale, options)

            let unitPrice = localPrice.replace(/[A-Z]/g, '')

            if (unit.price < 0) {
              priceColumn = <td>{i18nService.i18n(props.locale, 'unitList.tbd')}</td>
            } else {
              priceColumn = <td>{unitPrice}</td>
            }
          }
        }

        let availabilityColumn;
        let availabilityBadgeVariant;
        if (props.project && props.project.showAvailability) {
          const switchScope = unit.unitmodels.length ? unit.unitmodels[0].availability : unit.availability

          switch (switchScope) {
            case 'Available':
              availabilityBadgeVariant = 'primary'
              break;
            case 'Sold':
              availabilityBadgeVariant = 'danger'
              break;
            case 'Unavailable':
              availabilityBadgeVariant = 'danger'
              break;
            case 'Reserved':
              availabilityBadgeVariant = 'secondary'
              break;
            case 'Leased':
              availabilityBadgeVariant = 'danger'
              break;
            default:
              availabilityBadgeVariant = 'secondary'
          }

          let availabilityText = switchScope
          if (props.project.rentalObject && switchScope === 'Sold') {
            availabilityText = 'Leased'
          }

          availabilityColumn = (
            <td>
              <Badge variant={availabilityBadgeVariant}>
                {i18nService.translate(props.locale, availabilityText)}
              </Badge>
            </td>
          )
        }

        let deliveryDateColumn;
        if (props.project && props.project.deliveryDates) {
          if (availabilityBadgeVariant === 'danger') {
            deliveryDateColumn = <td></td>
          } else {
            deliveryDateColumn = (
              <td>
                {unit.deliveryDate ? Formatter.formatDate(unit.deliveryDate) : ''}
              </td>
            )
          }
        }

        let bedroomColumn;
        if (!props.project.landOnly && props.project.projectType !== 'commercial') {
          bedroomColumn = <td>{i18nService.translate(props.locale, unit.bedrooms)}</td>
        }

        unitRow = (
          <tr
            className="unit-row" key={`unit-${unit._id}`}
            onMouseEnter={() => hoverAction(unit)}
            onMouseLeave={() => hoverAction()}
            onClick={() => trackAndClick(unit)}
            >
            <td>{unit.name}</td>
            <td>{unit.squareFeet} {i18nService.i18n(props.locale, 'globals.ft²')}</td>
            {bedroomColumn}
            {priceColumn}
            {deliveryDateColumn}
            {availabilityColumn}
          </tr>
        )
      }

      return unitRow;
    })

    unitGrid = availableUnits.sort((a, b) => {
      if (props.tableSorting.asc) {
        return (a[props.tableSorting.property] > b[props.tableSorting.property]) ? 1 : -1
      } else {
        return (a[props.tableSorting.property] < b[props.tableSorting.property]) ? 1 : -1
      }
    }).map((unit, i) => {
      let unitColumn;
      if ((!props.filterByFloor || !props.filterByFloor.length || props.filterByFloor.indexOf(unit.floor) !== -1) && (!props.filterByBedrooms || props.filterByBedrooms === unit.bedrooms)) {
        let priceColumn;
        if (props.project && props.project.showPrices) {
          priceColumn = (
            <Col xs={7}>
              <small className="d-block text-muted">{i18nService.i18n(props.locale, 'unitList.price')}</small>
              <h6 className="mt-1">{unit.price ? (unit.price).toLocaleString(`${props.locale.match('showcase') ? 'en' : props.locale}-CA`, { style: 'currency', currency: 'CAD', minimumFractionDigits: 0 }).replace(/[A-Z]/g, '') : '?'}</h6>
            </Col>
          )
        }

        let availabilityColumn;
        if (props.project && props.project.showAvailability) {
          let badgeVariant;
          switch (unit.availability) {
            case 'Available':
              badgeVariant = 'primary'
              break;
            case 'Sold':
              badgeVariant = 'danger'
              break;
            case 'Reserved':
              badgeVariant = 'secondary'
              break;
            case 'Leased':
              badgeVariant = 'danger'
              break;
            default:
              badgeVariant = 'secondary'
          }

          let availabilityText = unit.availability
          if (props.project.rentalObject && unit.availability === 'Sold') {
            availabilityText = 'Leased'
          }

          availabilityColumn = (
            <Col xs={5}>
              <small className="d-block text-muted">{i18nService.i18n(props.locale, 'unitList.status')}</small>
              <Badge variant={badgeVariant} className="mt-1">
                {i18nService.translate(props.locale, availabilityText)}
              </Badge>
            </Col>
          )
        }

        let bedroomCount = parseInt(unit.bedrooms || 0);
        if (isNaN(bedroomCount)) bedroomCount = unit.bedrooms

        unitColumn = (
          <Col xs={12}
            className="mb-3"
            key={`unit-${unit._id}`}
            onClick={() => props.selectUnit(unit)}
            onMouseEnter={() => hoverAction(unit)}
            onMouseLeave={() => hoverAction()}
            >
            <Card className="unit-card h-100">
              <Card.Img variant="top" src={unit.layoutUrl || (unit.images.length ? unit.images[0] : '')} />
              <Card.Body>
                <Row className="mb-2">
                  <Col xs={3}>
                    <small className="d-block text-muted">{i18nService.i18n(props.locale, 'unitList.unit')}</small>
                    <h6 className="mt-1">{unit.name}</h6>
                  </Col>

                  <Col xs={4}>
                    <small className="d-block text-muted">{i18nService.i18n(props.locale, 'unitList.area')}</small>
                    <h6 className="mt-1">{unit.squareFeet} {i18nService.i18n(props.locale, 'globals.ft²')}</h6>
                  </Col>

                  <Col xs={5}>
                    <small className="d-block text-muted">{i18nService.i18n(props.locale, 'unitList.bedrooms')}</small>
                    <h6 className="mt-1">{bedroomCount}</h6>
                  </Col>
                </Row>

                <Row>
                  {priceColumn}
                  {availabilityColumn}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )
      }

      return unitColumn;
    })
  }

  function handleScrollFrame(values) {
    setVerticalThumbStyle((props.dynamicHeight - 97 - 52) * values.top)
  }

  function renderThumbVertical({ style, ...props }) {
    const customStyle = { top: `${verticalThumbStyle}px` }
    return (
      <div {...props} style={{ ...style, ...customStyle }} className="thumb-vertical"/>
    )
  }

  let scrollbarStyles;
  if (props.dynamicHeight) {
    scrollbarStyles = { height: (props.dynamicHeight - 97) || 0 }
  } else {
    scrollbarStyles = { minHeight: '300px' }
  }

  let bedroomsHeader;
  if (props.project && !props.project.landOnly && props.project.projectType !== 'commercial') {
    bedroomsHeader = (
      <th
        scope="col"
        onClick={() => props.setSortingBy('bedrooms')}
        >
        <span className="d-flex flex-row flex-nowrap align-items-center justify-content-start">
          {i18nService.i18n(props.locale, 'unitList.bedrooms')} {props.tableSorting.property === 'bedrooms' ? sortingIcon : ''}
        </span>
      </th>
    )
  }

  let listView = (
    <table className="table">
      <thead>
        <tr>
          <th
            scope="col"
            onClick={() => props.setSortingBy('name')}
            >
            <span className="d-flex flex-row flex-nowrap align-items-center justify-content-start">
              {i18nService.i18n(props.locale, 'unitList.unit')} {props.tableSorting.property === 'name' ? sortingIcon : ''}
            </span>
          </th>
          <th
            scope="col"
            onClick={() => props.setSortingBy('squareFeet')}
            >
            <span className="d-flex flex-row flex-nowrap align-items-center justify-content-start">
              {i18nService.i18n(props.locale, 'unitList.area')} {props.tableSorting.property === 'squareFeet' ? sortingIcon : ''}
            </span>
          </th>
          {bedroomsHeader}
          {priceHeader}
          {deliveryDateHeader}
          {availabilityHeader}
        </tr>
      </thead>
      <tbody>
        {unitList}
      </tbody>
    </table>
  )

  let gridView = (
    <div className="unit-grid">
      <Row>
        {unitGrid}
      </Row>
    </div>
  )

  return (
    <div className="unit-list">
      { props.gridView ? gridView : listView }
    </div>
  )
}
