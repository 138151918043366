import React, { useEffect, useState } from "react"

import axios from 'axios';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

import CustomSelect from '../../components/CustomSelect';

import './style.css';

import { MapPin, Maximize, Search } from "react-feather";

import Env from '../../Environments';

export default function Collection(props) {
  const [collection, setCollection] = useState({})
  const [fetchedCollection, setFetchedCollection] = useState(false)
  const [propertyType, setPropertyType] = useState()
  const [location, setLocation] = useState()
  const [locationOptions, setLocationOptions] = useState([])
  const [minimumSurfaceArea, setMinimumSurfaceArea] = useState()
  const [fullscreen, setFullscreen] = useState(false)

  function initializeMapbox(c) {
    let coordinates = [ -123.113889, 49.260833 ]
    if (c && c.lon && c.lat) {
      coordinates = [ c.lon, c.lat ]
    } else {
      for (let project of c.projects) {
        if (project.lon && project.lat) coordinates = [ project.lon, project.lat ]
      }
    }

    mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXM5N2NrIiwiYSI6ImNrczhwa25ndTB4MXIybnJuaGl2NXA0d2EifQ.Okc9gC3_brAyb8A4jIsyfQ';
    const map = new mapboxgl.Map({
      container: `mapbox-${c._id}`,
      style: 'mapbox://styles/mapbox/light-v10',
      center: coordinates,
      zoom: c.zoomLevel
    });

    let availableLocations = []

    let projectGeo = []
    for (let p of c.projects) {
      availableLocations.push(p.address)
      projectGeo.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [p.lon, p.lat]
        },
        properties: {
          id: p._id,
          title: p.name,
          description: p.address,
          cover: p.projectImageUrl,
          type: 'project'
        }
      })
    }

    const groupGeo = []
    for (let g of c.groups) {
      availableLocations.push(g.address)
      groupGeo.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [g.lon, g.lat]
        },
        properties: {
          id: g._id,
          title: g.name,
          description: g.address,
          cover: g.groupImageUrl,
          type: 'group'
        }
      })
    }

    if (!locationOptions.length) setLocationOptions(availableLocations)

    const geojson = {
      type: 'FeatureCollection',
      features: [...projectGeo, ...groupGeo]
    }

    // add markers to map
    geojson.features.forEach(function(marker) {

      // create a HTML element for each feature
      var el = document.createElement('div');
      el.className = 'marker';

      const popupContainer = document.createElement('div')
      popupContainer.innerHTML = `<div className="marker-content"><img src="${marker.properties.cover || ''}" className="marker-cover" /><h5 className="marker-title">${marker.properties.title}</h5><p className="marker-description">${marker.properties.description}</p></div>`

      popupContainer.addEventListener('click', evt => {
        evt.preventDefault()

        if (marker.properties.type === 'group') {
          props.setSelectedGroupId(marker.properties.id)
        } else {
          props.setSelectedProjectId(marker.properties.id)
        }
      });

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
          .setDOMContent(popupContainer)
        )
        .addTo(map);
    })
  }

  function toggleFullscreen() {
    if (fullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }

      setFullscreen(false)
    } else {
      const elem = document.querySelector('#planpoint-root');

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }

      setFullscreen(true)
    }

  }

  // eslint-disable-next-line
  useEffect(() => {
    async function fetchCollection() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': props.token
        },
        url: `${Env.url}/api/v1/collections/${props.collectionId}`
      })

      setCollection(response.data)

      initializeMapbox(response.data)
    }

    if (!fetchedCollection) {
      fetchCollection()
      setFetchedCollection(true)
    }
  })

  async function executeSearch(evt) {
    evt.preventDefault()

    let patchData = {}
    if (location) patchData.location = location.value
    if (propertyType) patchData.propertyType = propertyType.value
    if (minimumSurfaceArea) patchData.minimumSurfaceArea = minimumSurfaceArea

    const response = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': props.token
      },
      data: patchData,
      url: `${Env.url}/api/v1/collections/${props.collectionId}/search`
    })

    setCollection(response.data)

    initializeMapbox(response.data)
  }

  if (collection._id) {
    return (
      <div className="Collection">
        <div className="Collection-navbar">
        <div className="Collection-nav-item">
          <CustomSelect
            value={propertyType}
            options={[
              { value: '', label: 'Any' },
              { value: 'Studio', label: 'Studio' },
              { value: '1 bedroom', label: '1 bedroom' },
              { value: '1 bedroom + den', label: '1 bedroom + den' },
              { value: '2 bedrooms', label: '2 bedrooms' },
              { value: '2 bedrooms + den', label: '2 bedrooms + den' },
              { value: '3 bedrooms', label: '3 bedrooms' },
              { value: '3 bedrooms + den', label: '3 bedrooms + den' },
              { value: '4 bedrooms', label: '4 bedrooms' },
              { value: '4 bedrooms + den', label: '4 bedrooms + den' },
              { value: '5 bedrooms', label: '5 bedrooms' },
              { value: '5 bedrooms + den', label: '5 bedrooms + den' },
              { value: 'Penthouse', label: 'Penthouse' },
              { value: 'Townhouse', label: 'Townhouse' }
            ]}
            isSearchable={true}
            isClearable={true}
            hideSelectedOptions={false}
            isMulti={false}
            placeholder="Property type"
            onChange={e => setPropertyType(e)}
            />
        </div>
        <div className="Collection-nav-item">
          <CustomSelect
            value={location}
            options={[
              { value: undefined, label: 'Any' },
              ...locationOptions.map(lo => ({ value: lo, label: lo }))
            ]}
            isSearchable={true}
            isClearable={true}
            hideSelectedOptions={false}
            isMulti={false}
            placeholder="Property location"
            onChange={e => setLocation(e)}
            />
        </div>
        <div className="Collection-nav-item">
          <input
            type="number"
            value={minimumSurfaceArea}
            onChange={e => setMinimumSurfaceArea(e.target.value)}
            className="Collection-nav-input"
            placeholder="Minimum surface area (ft²)"
            />
        </div>
        <div className="Collection-nav-item Collection-nav-button-container">
          <button className="Collection-nav-button" onClick={e => executeSearch(e)}>
            <Search size={24} /> <span className="Collection-nav-button-label">Search</span>
          </button>
          <div className="Collection-nav-button-group">
            <div
              onClick={() => toggleFullscreen()}
              className={`Collection-nav-button ${fullscreen ? '' : 'Collection-nav-button--active'}`}>
              <MapPin size={24} />
            </div>
            <div
              onClick={() => toggleFullscreen()}
              className={`Collection-nav-button ${fullscreen ? 'Collection-nav-button--active' : ''}`}>
              <Maximize size={24} />
            </div>
          </div>
        </div>
        </div>
        <div className="Collection-meta">
          <h5>{collection.projects.length+collection.groups.length} results found</h5>
        </div>
        <div className="Collection-map" id={`mapbox-${collection._id}`} style={{ minHeight: '60vw' }}></div>
      </div>
    )
  } else {
    return (
      <div className="Collection">
        <div className="Collection-navbar"></div>
        <div className="Collection-meta"></div>
        <div className="Collection-map"></div>
      </div>
    )
  }
}