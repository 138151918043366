import React, { useState, useRef, useCallback, useEffect } from 'react';

export default function RangeSlider(props) {
  const [minVal, setMinVal] = useState(props.min);
  const [maxVal, setMaxVal] = useState(props.max);

  const minValRef = useRef(props.min);
  const maxValRef = useRef(props.max);

  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(value => Math.round(((value - props.min) / (props.max - props.min)) * 100), [props.min, props.max]);

  useEffect(() => {
    if (props.min < minVal) setMinVal(props.min)
    if (props.max > maxVal) setMaxVal(props.max)
    // eslint-disable-next-line
  }, [props.min, props.max])

  // Set width of the range to change from the left side
  useEffect(() => {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(maxValRef.current);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
  }, [minVal, getPercent]);

  // Set width of the range to change from the right side
  useEffect(() => {
      const minPercent = getPercent(minValRef.current);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
  }, [maxVal, getPercent]);

  return (
    <div className="rsr-slider-container">
      <input
        type="range"
        min={props.min}
        max={props.max}
        value={minVal}
        onChange={event => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          props.setMinVal(value);
          minValRef.current = value;
        }}
        className="thumb thumb-left"
        style={{ zIndex: minVal > props.max - 100 && "5" }}
        />

      <input
        type="range"
        min={props.min}
        max={props.max}
        value={maxVal}
        onChange={event => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          props.setMaxVal(value);
          maxValRef.current = value;
        }}
        className="thumb thumb-right"
        />

      <div className="rsr-slider">
         <div className="rsr-slider-track" />
         <div ref={range} className="rsr-slider-range" />
      </div>
    </div>
  )
}
