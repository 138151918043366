import React, { useState, useEffect } from 'react';

import { X } from 'react-feather';

import './Modal.css'

export default function Modal(props) {
  const [modalStyle, setModalStyle] = useState({
    display: props.show ? 'flex' : 'none',
  })

  useEffect(() => {
    setModalStyle({
      display: props.show ? 'flex' : 'none'
    })
  }, [props.show])

  function hideAndClose() {
    setModalStyle({ opacity: 0 })

    setTimeout(() => {
      props.onClose()
    }, 250)
  }

  return (
    <div className="EmbedModal Modal__overlay Modal__overlay--full" style={modalStyle}>
      <div className="Modal__inner">
        <div className="Modal__toolbar">
          <X size={30} className="Modal__close" color="white" onClick={() => hideAndClose()}/>
        </div>

        <div className="Modal__content overflow-auto" dangerouslySetInnerHTML={{ __html: props.content }}>
        </div>
      </div>
    </div>
  )
}
