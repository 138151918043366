import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Env from '../../Environments';

import Group from '../../views/Group/Group';
import Exterior from '../../views/Exterior/Exterior';
import Floorplan from '../../views/Floorplan/Floorplan';
import Unit from '../../views/Unit/Unit';

import '../../App.css';

export default function GroupOrProject(props) {
  const [isLoading, setIsLoading] = useState(true)
  const [projectAssetsFetched, setProjectAssetsFetched] = useState(false)
  const [availableBedrooms, setAvailableBedrooms] = useState([])

  // variables to prevent future loading
  let [project, setProject] = useState();
  let [floors, setFloors] = useState();
  let [units, setUnits] = useState();

  let [token, setToken] = useState();
  let [projectId, setProjectId] = useState();
  let [groupId, setGroupId] = useState();
  let [locale, setLocale] = useState();

  let [scope, setScope] = useState();
  let [selectedUnit, setSelectedUnit] = useState();
  let [selectedFloor, setSelectedFloor] = useState();
  let [selectedProject, setSelectedProject] = useState();

  useEffect(() => {
    setToken(props.token)
    setProjectId(props.projectId)
    setGroupId(props.groupId)
    setLocale(props.locale)
  }, [props])

  async function loadProjectAssets(p) {
    setFloors([])
    setUnits([])
    setProjectId(p._id)
    setProject(p)
    setSelectedProject(p)
    setSelectedUnit()
    setSelectedFloor()

    // fetch floors
    let projectUnits = []
    let projectFloors = []

    // before we get into this, load a light version of all floors
    const lightFloors = await axios({
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      },
      params: { project: p._id },
      url: `${Env.url}/api/v1/floors`
    })

    // set light floors (no units attached)
    setFloors(lightFloors.data)

    setScope('exterior')

    // continue pulling in the "real" floors with all their units
    for (let floor of p.floors) {
      const r2 = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': token
        },
        url: `${Env.url}/api/v1/floors/${floor}`
      })

      projectFloors.push(r2.data)
      projectUnits = [...projectUnits, ...r2.data.units]

      // setFloors(projectFloors)
      setUnits(projectUnits)
    }

    // once all units are loaded, update the floors
    setFloors(projectFloors)

    // configure bedroom types
    let bedroomTypes = Array.from(new Set(projectUnits.map(u => u.bedrooms)))
    if (bedroomTypes.indexOf('Studio') !== -1) {
      setAvailableBedrooms(['Studio', ...bedroomTypes.filter(b => b !== 'Studio').sort()])
    } else {
      setAvailableBedrooms(bedroomTypes.sort())
    }
  }

  useEffect(() => {
    async function fetchProjectAssets() {
      // fetch the project
      const projectResponse = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': token
        },
        url: `${Env.url}/api/v1/projects/${projectId}`
      })

      const p = projectResponse.data
      setProject(p)
      setSelectedProject(p)

      // fetch floors
      let projectUnits = []
      let projectFloors = []

      // before we get into this, load a light version of all floors
      const lightFloors = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': token
        },
        params: { project: p._id },
        url: `${Env.url}/api/v1/floors`
      })

      // set light floors (no units attached)
      setFloors(lightFloors.data)

      setIsLoading(false)

      // continue pulling in the "real" floors with all their units
      for (let floor of p.floors) {
        const r2 = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': token
          },
          url: `${Env.url}/api/v1/floors/${floor}`
        })

        projectFloors.push(r2.data)
        projectUnits = [...projectUnits, ...r2.data.units]

        // setFloors(projectFloors)
        setUnits(projectUnits)
      }

      // once all units are loaded, update the floors
      setFloors(projectFloors)

      // configure bedroom types
      let bedroomTypes = Array.from(new Set(projectUnits.map(u => u.bedrooms)))
      if (bedroomTypes.indexOf('Studio') !== -1) {
        setAvailableBedrooms(['Studio', ...bedroomTypes.filter(b => b !== 'Studio').sort()])
      } else {
        setAvailableBedrooms(bedroomTypes.sort())
      }
    }

    const secondaryScopes = ['group', 'floor', 'unit']
    if (projectId && token && !secondaryScopes.includes(scope) && !projectAssetsFetched) {
      setProjectAssetsFetched(true)
      fetchProjectAssets()
    }
  }, [projectId, token, scope, projectAssetsFetched])

  function selectUnit(unit, animate = true) {
    setTimeout( function() {
      setSelectedUnit(unit)
      setSelectedFloor()
      setScope('unit')

      if (!animate) setIsLoading(false)
    }, 250)
  }

  function selectProject(project, animate = true) {
    setTimeout( function() {
      loadProjectAssets(project)
    }, 250)
  }

  function selectFloor(floor, animate = true) {
    setTimeout( function() {
      setSelectedUnit()
      setSelectedFloor(floor)
      setScope('floor')
    }, 250)
  }

  function selectExterior(animate = true) {
    setTimeout( function() {
      setSelectedUnit()
      setSelectedFloor()
      setScope('exterior')
    }, 250)
  }

  function selectGroup(animate = true) {
    setTimeout( function() {
      setSelectedUnit()
      setSelectedFloor()
      setSelectedProject()
      setScope('group')
    }, 250)
  }

  const exteriorScope = (
    <Exterior
      token={token}
      locale={locale}
      setIsLoading={loadingState => setIsLoading(loadingState)}
      projectId={projectId || (selectedProject || {})._id}
      selectedProject={selectedProject}
      groupId={groupId}
      selectUnit={(unit) => selectUnit(unit)}
      selectFloor={(floor, animate) => selectFloor(floor, animate)}
      selectGroup={() => selectGroup()}
      floors={floors}
      setFloors={v => setFloors(v)}
      units={units}
      setUnits={v => setUnits(v)}
      project={project}
      setProject={v => setProject(v)}
      availableBedrooms={availableBedrooms}
      collectionId={props.collectionId}
      setSelectedProjectId={e => props.setSelectedProjectId(e)}
      />
  )

  const groupScope = (
    <Group
      token={token}
      locale={locale}
      setIsLoading={loadingState => setIsLoading(loadingState)}
      groupId={groupId}
      selectUnit={(unit) => selectUnit(unit)}
      selectProject={(project, animate) => selectProject(project, animate)}
      collectionId={props.collectionId}
      setSelectedGroupId={e => props.setSelectedGroupId(e)}
      />
  )

  let activeScope;
  switch (scope) {
    case 'group':
      activeScope = groupScope
      break;
    case 'exterior':
      activeScope = exteriorScope
      break;
    case 'floor':
      activeScope = <Floorplan
        token={token}
        locale={locale}
        setIsLoading={loadingState => setIsLoading(loadingState)}
        projectId={projectId}
        selectedProject={selectedProject}
        groupId={groupId}
        floor={selectedFloor}
        selectUnit={(unit) => selectUnit(unit)}
        selectFloor={(floor, animate) => selectFloor(floor, animate)}
        selectExterior={() => selectExterior()}
        floors={floors}
        setFloors={v => setFloors(v)}
        units={units}
        setUnits={v => setUnits(v)}
        project={project}
        setProject={v => setProject(v)}
        availableBedrooms={availableBedrooms}
        />
      break;
    case 'unit':
      activeScope = <Unit
        token={token}
        locale={locale}
        setIsLoading={loadingState => setIsLoading(loadingState)}
        projectId={projectId}
        selectedProject={selectedProject}
        groupId={groupId}
        unit={selectedUnit}
        selectUnit={(unit, animate) => selectUnit(unit, animate)}
        selectFloor={(floor, animate) => selectFloor(floor, animate)}
        floors={floors}
        setFloors={v => setFloors(v)}
        units={units}
        setUnits={v => setUnits(v)}
        project={project}
        setProject={v => setProject(v)}
        />
      break;
    default:
      if (groupId) {
        activeScope = groupScope
      } else {
        activeScope = exteriorScope
      }
      break;
  }

  return (
    <div className="App">
      <div className={`loading-overlay ${isLoading ? 'loading-overlay--active' : ''}`}></div>

      {activeScope}
    </div>
  );
}